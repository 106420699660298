import { TableDataTextProps } from '../../../interfaces/tokenHolderInterface';
import { Text } from '@chakra-ui/react';
import React from 'react';

export const TextHeading = (props: TableDataTextProps) => {
  const { text } = props;
  return (
    <Text fontSize={'16px'} fontWeight="600" padding="0 26px 0 26px">
      {text}
    </Text>
  );
};
