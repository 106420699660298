import React from 'react';

interface InputFieldProps {
  label: string;
  placeholder: string;
  value: string | number;
  onChange: (value: string) => void;
}

const InputField: React.FC<InputFieldProps> = ({ label, placeholder, value, onChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <span
        style={{
          color: 'var(--Text-Primary-default, #000)',
          fontFamily: 'Poppins',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '22px',
        }}
      >
        {label}
      </span>
      <input
        type="text"
        placeholder={placeholder}
        value={value.toString()}
        onChange={(e) => onChange(e.target.value)}
        style={{
          display: 'flex',
          height: '40px',
          padding: 'var(--Notification-lineHeightLG, 0px) var(--Input-controlPaddingHorizontal, 12px)',
          alignItems: 'center',
          gap: '10px',
          alignSelf: 'stretch',
          borderRadius: '8px',
          border: '1px solid rgba(0, 0, 0, 0.15)',
          background: 'var(--Input-colorBgContainer, #FFF)',
          color: 'var(--Text-Primary-default, #000)',
          fontFamily: 'Poppins',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
        }}
      />
    </div>
  );
};

export default InputField;
