import { Box, Image, Spinner, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import questionMarkIcon from '../../../assets/images/svgs/questionMarkIcon.svg';
import platFormLogos from '../../../assets/images/svgs/platFormLogos.svg';
import marketCapShido from '../../../assets/images/svgs/marketCapShido.svg';
import configureAxios from '../../../AxiosClient';
import { loadingStore } from '../../../store';
import { StatisticsData } from '../../../interfaces';
interface StatsProps {
  img: string;
  icon: string;
  percentage: string;
  heading: string;
  amount: number | undefined;
}

export const Stats = (props: StatsProps) => {
  const { img, icon, heading, amount } = props;
  return (
    <Box
      height={['44px']}
      w={['100%']}
      borderBottom="1px solid rgba(242, 243, 245, 0.14)"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bg="white"
      mt="9px"
      h="65px"
      borderRadius={'12px'}
      padding="10px 15px"
      className="gsap-item"
    >
      <Box display="flex" gap={'16px'}>
        <Image src={img} alt="logo" />
        <Box>
          <Text color={'#000'} fontSize="14px" fontWeight={600}>
            {heading}
          </Text>
          <Text color={'#909090'} fontSize="14px" fontWeight={400}>
            {amount}
          </Text>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        gap={'16px'}
        borderRadius="12px"
        bg="#EFFBF5"
        padding="4px 10px"
        color="black"
      >
        {/* <Text color={'#39CA7F'} fontSize="14px" fontWeight={600}>
          +5%
        </Text> */}
        {/* <Image src={icon} alt="logo" /> */}
      </Box>
    </Box>
  );
};

function Statistics() {
  const axiosInstance = configureAxios();
  const setLoading = loadingStore((state: any) => state.setLoading);
  const loading = loadingStore((state: any) => state.loading);
  const [tokenHolderInfo, setTokenHolderInfo] = useState<StatisticsData>();

  const getTokenHolderData = () => {
    axiosInstance
      .get('https://dev.api.covey.academy/api/token/stats/')
      .then((res) => {
        const isStatusOk = res.status === 200;
        if (isStatusOk) {
          setLoading(false);
          setTokenHolderInfo(res.data);
        } else {
          setLoading(false);
        }
      })
      .catch((err: unknown) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    getTokenHolderData();
  }, []);

  return (
    <>
      {' '}
      {loading ? (
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      ) : (
        <>
          <Stats
            heading="Token Holders"
            img={platFormLogos}
            icon={questionMarkIcon}
            percentage="+5%"
            amount={tokenHolderInfo && tokenHolderInfo?.wallet_numbers}
          />
          <Stats
            heading="Market Cap:SHIDO"
            img={marketCapShido}
            icon={questionMarkIcon}
            percentage="+8%"
            amount={tokenHolderInfo && tokenHolderInfo?.market_cap}
          />
        </>
      )}
    </>
  );
}
export default Statistics;
