import { AddIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/react';
import { Button as AntButton, Space, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DeleteModal from '../components/modals/DeleteModal';
import AddressBookEditModal from '../components/modals/AddressBookEditModal';

export interface DataType {
  id: number;
  wallet_address: string;
  name: string;
  created_at: string;
  updated_at: string;
}

interface ApiResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: DataType[];
}

const AddressBooks: React.FC = () => {
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState<DataType | null>(null);
  const [deletingRecord, setDeletingRecord] = useState<DataType | null>(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get<ApiResponse>('https://dev.api.covey.academy/api/user/address-book/', {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setTableData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEdit = (record: DataType) => {
    setEditingRecord(record);
    setIsAddingNew(false);
    setIsShowSuccessModal(true);
  };

  const handleDelete = (record: DataType) => {
    setDeletingRecord(record);
    setIsShowDeleteModal(true);
  };

  const handleAdd = () => {
    setEditingRecord(null);
    setIsAddingNew(true);
    setIsShowSuccessModal(true);
  };

  const handleSaveChanges = async (updatedRecord: DataType) => {
    try {
      if (isAddingNew) {
        await axios.put(
          'https://dev.api.covey.academy/api/user/address-book/',
          {
            wallet_address: updatedRecord.wallet_address,
            name: updatedRecord.name,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
          },
        );
      } else {
        await axios.patch(
          'https://dev.api.covey.academy/api/user/address-book/',
          {
            id: updatedRecord.id,
            wallet_address: updatedRecord.wallet_address,
            name: updatedRecord.name,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
          },
        );
      }
      fetchData();
      setIsShowSuccessModal(false);
      setEditingRecord(null);
      setIsAddingNew(false);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const handleConfirmDelete = async () => {
    if (deletingRecord) {
      try {
        await axios.delete('https://dev.api.covey.academy/api/user/address-book/', {
          data: { id: deletingRecord.id },
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        });
        fetchData();
        setIsShowDeleteModal(false);
        setDeletingRecord(null);
      } catch (error) {
        console.error('Error deleting record:', error);
      }
    }
  };

  const getUniqueFilters = (data: DataType[], key: keyof DataType) => {
    const uniqueValues = Array.from(new Set(data.map((item) => item[key])));
    return uniqueValues.map((value) => ({ text: value, value }));
  };

  const columns: ColumnType<DataType>[] = [
    {
      title: 'Address',
      dataIndex: 'wallet_address',
      key: 'wallet_address',
      sorter: (a, b) => a.wallet_address.localeCompare(b.wallet_address),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filters: getUniqueFilters(tableData, 'name'),
      onFilter: (value, record) => record.name.indexOf(value as string) === 0,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <AntButton
            type="link"
            onClick={() => handleEdit(record)}
            style={{ color: '#2D8DFB', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}
          >
            Edit
          </AntButton>
          <AntButton
            type="link"
            onClick={() => handleDelete(record)}
            style={{ color: '#FF4D4F', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}
          >
            Delete
          </AntButton>
        </Space>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Box marginTop="40px">
      <Text
        color="rgba(0, 0, 0, 0.88)"
        fontSize="14px"
        fontStyle="normal"
        fontWeight="400"
        lineHeight="22px"
        marginBottom="8px"
      >
        My Address Book
      </Text>
      <AntButton type="primary" icon={<AddIcon />} onClick={handleAdd} style={{ marginBottom: '16px' }}>
        Add New Address
      </AntButton>
      <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} rowKey="id" />
      <AddressBookEditModal
        isShowModal={isShowSuccessModal}
        setIsShowModal={setIsShowSuccessModal}
        editingRecord={editingRecord}
        onSaveChanges={handleSaveChanges}
        isAddingNew={isAddingNew}
      />
      <DeleteModal
        showModal={isShowDeleteModal}
        isShowModal={setIsShowDeleteModal}
        onConfirmDelete={handleConfirmDelete}
      />
    </Box>
  );
};

export default AddressBooks;
