import { Box, Image, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import { type } from '@testing-library/user-event/dist/type';
import { loadingStore } from '../../../store';
import { AvaxIcon } from '../../../Icons/Avax';

type TokenInfoProps = {
  image?: string;
  name: string;
  tokenBalance: string;
};

export const TokenInfo = (props: TokenInfoProps) => {
  const setLoading = loadingStore((state: any) => state.setLoading);
  const loading = loadingStore((state: any) => state.loading);
  const { image, name, tokenBalance } = props;

  return (
    <>
      {loading ? (
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      ) : (
        <Box display={'flex'} gap="10px" justifyContent={'space-between'} height="72px">
          <Box display={'flex'} gap="10px">
            <Box>
              <AvaxIcon boxSize={'10'} />
              {/* <Image src={image} /> */}
            </Box>
            <Box>
              <Text fontSize={'12px'} fontWeight="600" color={'#000'}>
                {name.split(' ')[0]}
              </Text>
              {/* <Text fontSize={'12px'} fontWeight="400" color={'#909090'}>
            ERC20
          </Text> */}
            </Box>
          </Box>
          <Box>
            <Text fontSize={'12px'} fontWeight="600" color={'#000'} textAlign="end">
              {name.split(' ')[0]}
            </Text>

            <Text fontSize={'12px'} fontWeight="400" color={'#909090'}>
              {Math.round(Number(tokenBalance))}
            </Text>
          </Box>
        </Box>
      )}
    </>
  );
};
