import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import { ChakraProvider } from '@chakra-ui/react';
import { CustomTheme } from './theme/theme';
import reportWebVitals from './reportWebVitals';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ChakraProvider>
    <App />
  </ChakraProvider>,
);

reportWebVitals();
