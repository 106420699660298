import React, { useState, useEffect, useRef } from 'react';
import { DatePicker, Space, Button, message, Select } from 'antd';
import moment from 'moment';
import { Sankey } from '@antv/g2plot';
import api, { SankeyParams } from '../api/ApiCalls/user';

const { RangePicker } = DatePicker;
const { Option } = Select;

const SankeyChart: React.FC = () => {
  const [dateRange, setDateRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
  const [originTitles, setOriginTitles] = useState<string[]>(['All']);
  const [destinationTitles, setDestinationTitles] = useState<string[]>(['All']);
  const [inflowOutflowTitles, setInflowOutflowTitles] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<Sankey | null>(null);

  useEffect(() => {
    fetchInflowOutflowTitles();
  }, []);

  const fetchInflowOutflowTitles = async () => {
    const { data, error } = await api.getInflowOutflowTitles();
    if (error) {
      message.error('Failed to fetch inflow/outflow titles');
    } else {
      setInflowOutflowTitles(['All', ...data]);
    }
  };

  const handleDateChange = (dates: [moment.Moment | null, moment.Moment | null]) => {
    setDateRange(dates);
  };

  const handleOriginChange = (values: string[]) => {
    setOriginTitles(values.includes('All') ? ['All'] : values);
  };

  const handleDestinationChange = (values: string[]) => {
    setDestinationTitles(values.includes('All') ? ['All'] : values);
  };

  const fetchData = async () => {
    setLoading(true);

    const params: SankeyParams = {};
    if (dateRange[0] && dateRange[1]) {
      params.start_date = dateRange[0].format('YYYY-MM-DD');
      params.end_date = dateRange[1].format('YYYY-MM-DD');
    }
    if (!originTitles.includes('All')) {
      params.origin_titles = originTitles;
    }
    if (!destinationTitles.includes('All')) {
      params.destination_titles = destinationTitles;
    }

    console.log('Fetching data with params:', params);

    const { data, error } = await api.getSankeyTransactions(params);

    if (error) {
      message.error(error);
    } else {
      console.log('Received data:', data);
      renderSankey(data);
    }

    setLoading(false);
  };

  const renderSankey = (data: any[]) => {
    if (containerRef.current) {
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      chartRef.current = new Sankey(containerRef.current, {
        data: data,
        sourceField: 'source',
        targetField: 'target',
        weightField: 'value',
        nodeWidthRatio: 0.008,
        nodePaddingRatio: 0.03,
        nodeDraggable: true,
      });

      chartRef.current.render();
    }
  };

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
      <Space>
        <RangePicker onChange={(dates) => handleDateChange(dates as [moment.Moment | null, moment.Moment | null])} />
        <Select
          mode="multiple"
          style={{ width: 300 }}
          placeholder="Select Origins"
          onChange={handleOriginChange}
          value={originTitles}
        >
          {inflowOutflowTitles.map((title) => (
            <Option key={`origin-${title}`} value={title}>
              {title}
            </Option>
          ))}
        </Select>
        <Select
          mode="multiple"
          style={{ width: 300 }}
          placeholder="Select Destinations"
          onChange={handleDestinationChange}
          value={destinationTitles}
        >
          {inflowOutflowTitles.map((title) => (
            <Option key={`destination-${title}`} value={title}>
              {title}
            </Option>
          ))}
        </Select>
        <Button onClick={fetchData} loading={loading}>
          Fetch Data
        </Button>
      </Space>

      <div ref={containerRef} style={{ height: 600 }} />
    </Space>
  );
};

export default SankeyChart;
