import React, { useEffect, useState } from 'react';
import { Button, Row } from 'antd';
import Cross from '../../assets/images/svgs/cross.svg';
import AppModal from './AppModal';
import InputField from './InputField';
import RadioButtonGroup from './RadioButtonGroup';

interface DataType {
  key: string;
  id: number;
  title: string;
  address: string;
  status: 'Receiver' | 'Sender';
}

interface FormDataType {
  title: string;
  address: string;
  status: 'Receiver' | 'Sender';
}

interface IProps {
  isShowModal: boolean;
  setIsShowModal: (e: boolean) => void;
  editingRecord: DataType | null;
  onSaveChanges: (updatedRecord: DataType) => Promise<void>;
  isAddingNew: boolean;
}

const SuccessModal: React.FC<IProps> = ({ isShowModal, setIsShowModal, editingRecord, onSaveChanges, isAddingNew }) => {
  const [formData, setFormData] = useState<FormDataType>({
    title: '',
    address: '',
    status: 'Sender',
  });

  useEffect(() => {
    if (editingRecord) {
      setFormData({
        title: editingRecord.title,
        address: editingRecord.address,
        status: editingRecord.status,
      });
    } else {
      setFormData({
        title: '',
        address: '',
        status: 'Sender',
      });
    }
  }, [editingRecord]);

  const inputFields = [
    { label: 'Title', key: 'title' as keyof FormDataType, placeholder: 'Enter title' },
    { label: 'Address', key: 'address' as keyof FormDataType, placeholder: '0x545445455445' },
  ];

  const radioOptions = [
    { id: 'Sender', label: 'Sender' },
    { id: 'Receiver', label: 'Receiver' },
  ];

  const handleInputChange = (key: keyof FormDataType, value: string) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleStatusChange = (value: string) => {
    setFormData((prev) => ({ ...prev, status: value as 'Receiver' | 'Sender' }));
  };

  const handleSaveChanges = async () => {
    const updatedRecord: DataType = {
      key: editingRecord?.key || formData.title,
      id: editingRecord?.id || 0, // Use 0 for new records, existing id for edits
      ...formData,
    };
    await onSaveChanges(updatedRecord);
  };

  return (
    <AppModal open={isShowModal} onCancel={() => setIsShowModal(false)} onClose={() => setIsShowModal(false)}>
      <Row
        style={{
          width: '401px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '24px',
        }}
      >
        <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch' }}>
          <span
            style={{
              color: 'rgba(0, 0, 0, 0.88)',
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
            }}
          >
            {isAddingNew ? 'Add new entry' : 'Edit your entry'}
          </span>
          <div style={{ cursor: 'pointer' }} onClick={() => setIsShowModal(false)}>
            <img src={Cross} alt="Close" />
          </div>
        </Row>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
          {inputFields.map((field) => (
            <InputField
              key={field.key}
              label={field.label}
              placeholder={field.placeholder}
              value={formData[field.key]}
              onChange={(value) => handleInputChange(field.key, value)}
            />
          ))}
          <RadioButtonGroup options={radioOptions} value={formData.status} onChange={handleStatusChange} />
        </div>
        <Button
          onClick={handleSaveChanges}
          style={{
            display: 'flex',
            padding: '0px 16px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            borderRadius: '6px',
            border: '1px solid #2D8DFB',
            background: '#2D8DFB',
            color: '#FFF',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
          }}
        >
          Save Changes
        </Button>
      </Row>
    </AppModal>
  );
};

export default SuccessModal;
