/* eslint-disable prettier/prettier */
import { gsap } from 'gsap';

// Content_BlockTL----------------------
export function Content_BlockTL(element) {

  let Content_BlockTL = gsap.timeline({
    defaults: { duration: 1.5, ease: "power3.out", },
    // repeat: -1, repeatDelay: 0, yoyo: 0,
  });
  Content_BlockTL
    .from(element.querySelectorAll(".text-item"), {
      y: 22, opacity: 0, stagger: 0.13,
    }, "<+=0.00")
    .from(element.querySelectorAll(".gsap-item"), {
      y: 22, opacity: 0, stagger: 0.13,
    }, "<+=0.13")

}
// /Content_BlockTL----------------------