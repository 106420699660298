import { Box, IconButton, Image } from '@chakra-ui/react';
import { gsap } from 'gsap';
import React, { useLayoutEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarTL } from '../../assets/animation/Sidebar';
import coveyLogo from '../../assets/images/svgs/coveyLogo.svg';
import logoText from '../../assets/images/svgs/logoText.svg';
import { ArrowIcon } from '../../Icons/ArrowIcon';
import { LeftArrow } from '../../Icons/ArrowLeft';
import { SidebarOption, SidebarProps } from '../../interfaces';
import SidebarItem from './SidebarItem';

function Sidebar({ sideBarOpen, activeView, sidebarOptions, setActiveView, setSideBarOpen }: SidebarProps) {
  const SidebarRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      SidebarTL(SidebarRef.current);
    }, SidebarRef);

    return () => ctx.revert();
  }, [SidebarRef]);

  return (
    <Box
      className="sidebar-container"
      ref={SidebarRef}
      minWidth={sideBarOpen ? ['278px'] : '98px'}
      transition="0.2s all"
      padding={'24px'}
      cursor="pointer"
      display={[sideBarOpen ? 'flex' : 'none', sideBarOpen ? 'flex' : 'none', 'flex', 'flex', 'flex']}
      flexDirection="column"
      position={['absolute', 'absolute', 'relative']}
      bg={['#353535', '#353535', 'unset']}
      h={['100%', '100%', 'unset']}
      zIndex={2}
      style={{ transition: 'all 0.3s 0s ease-out' }}
    >
      <Box
        className="sidebar-wrap"
        style={{ width: sideBarOpen ? '230px' : '48px', transition: 'all 0.3s 0s ease-out' }}
      >
        <Box
          padding={sideBarOpen ? '24px 0' : '36px 0'}
          display={'flex'}
          justifyContent={sideBarOpen ? 'flex-start' : 'center'}
          alignItems="center"
          gap={3}
          marginBottom={sideBarOpen ? '24px' : '14px'}
          style={{ transition: 'all 0.3s 0s ease-out' }}
        >
          <Box className="gsap-item-logo" display={'flex'} gap={3} style={{ width: '100%' }}>
            <Image src={coveyLogo} alt="logo" />
            {sideBarOpen && <Image src={logoText} alt="Dan Abramov" />}
          </Box>
        </Box>
        <Box
          className="gsap-item-btn"
          display={'flex'}
          justifyContent="center"
          alignItems={'center'}
          position="absolute"
          top={sideBarOpen ? '50px' : '120px'}
          right="-20px"
          style={{ transition: 'all 0.3s 0s ease-out' }}
        >
          <IconButton
            borderRadius="50%"
            backgroundColor={'#FFFFFF'}
            onClick={() => (sideBarOpen ? setSideBarOpen(false) : setSideBarOpen(true))}
            icon={sideBarOpen ? <LeftArrow boxSize={[6]} /> : <ArrowIcon boxSize={[6]} />}
            aria-label="Close Sidebar"
          />
        </Box>
        <Box
          className="SidebarItem-wrap"
          style={{ overflow: 'hidden' }}
          display={'flex'}
          flexDirection="column"
          position={'absolute'}
          height="80%"
        >
          {sidebarOptions.map((sideBarOption: SidebarOption) => (
            <Box
              className="gsap-item"
              key={sideBarOption.key}
              style={sideBarOption.key === 'addressBooks' ? { marginTop: 'auto' } : { marginTop: 'unset' }}
            >
              <NavLink to={sideBarOption.href}>
                <SidebarItem
                  sideBarItemKey={sideBarOption.key}
                  sideBarIcon={sideBarOption.sideBarIcon}
                  text={sideBarOption.text}
                  sideBarOpen={sideBarOpen}
                  active={activeView === sideBarOption.key}
                  setActiveView={() => setActiveView(sideBarOption.key)}
                  last={
                    sideBarOption.key === 'addressBooks' ||
                    sideBarOption.key === 'logout' ||
                    sideBarOption.key === 'settings'
                  }
                />
              </NavLink>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default Sidebar;
