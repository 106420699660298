import { Radio } from 'antd';
import React from 'react';

interface RadioOption {
  id: string;
  label: string;
}

interface RadioButtonGroupProps {
  options: RadioOption[];
  value: string;
  onChange: (value: string) => void;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ options, value, onChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        width: '100%',
      }}
    >
      <span
        style={{
          color: 'var(--Text-Primary-default, #000)',
          fontFamily: 'Poppins',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '22px',
        }}
      >
        Select Type
      </span>
      <Radio.Group
        onChange={(e) => onChange(e.target.value)}
        value={value}
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
        }}
      >
        {options.map(({ id, label }) => (
          <Radio
            key={id}
            value={id}
            style={{
              display: 'flex',
              height: '32px',
              padding: 'var(--Notification-lineHeightLG, 0px) var(--Input-controlPaddingHorizontal, 12px)',
              alignItems: 'center',
              gap: 'var(--Radio-marginXS, 8px)',
            }}
          >
            <span
              style={{
                color: 'rgba(0, 0, 0, 0.88)',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
              }}
            >
              {label}
            </span>
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

export default RadioButtonGroup;
