import { Box, Spinner, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import configureAxios from '../../../AxiosClient';
import { loadingStore } from '../../../store';

function SpeedoMeter() {
  const setLoading = loadingStore((state: any) => state.setLoading);
  const loading = loadingStore((state: any) => state.loading);
  const [fearAndGreedPercentage, setFearAndGreedPercentage] = useState<number>();
  const toast = useToast();
  const axiosInstance = configureAxios();

  const getFearAndGreed = () => {
    axiosInstance
      .get('https://dev.api.covey.academy/fear-n-greed/')
      .then((res) => {
        const isStatusOk = res.status === 200;
        if (isStatusOk) {
          setLoading(false);

          setFearAndGreedPercentage(res.data.data[0].value);
        } else {
          setLoading(false);
          toast({
            title: 'There was an error fetching data',
            status: 'error',
            isClosable: true,
          });
        }
      })
      .catch((err: unknown) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    getFearAndGreed();
  }, []);

  return (
    <Box
      bg="white"
      w="inherit"
      mt="16px"
      display={'flex'}
      justifyContent="center"
      alignItems="center"
      borderRadius="20px"
      className="gsap-item"
    >
      {loading ? (
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      ) : (
        <ReactSpeedometer
          forceRender={true}
          maxSegmentLabels={0}
          customSegmentStops={[0, 77, 100]}
          segmentColors={['#4096FF', '#E9E9E9']}
          needleColor={'black'}
          needleTransitionDuration={1000}
          minValue={0}
          maxValue={100}
          ringWidth={15}
          value={fearAndGreedPercentage}
          textColor={'#4096FF'}
          needleHeightRatio={0.5}
          width={230} // Adjust the width to decrease the overall size
          height={window.innerWidth > 600 ? 195 : 150}
        />
      )}
    </Box>
  );
}
export default SpeedoMeter;
