import { Box, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react';
import { Chart, ChartOptions, registerables } from 'chart.js';
import 'chartjs-adapter-moment';
import { Line } from 'react-chartjs-2';

import configureAxios from '../../../AxiosClient';
import { loadingStore } from '../../../store';

Chart.register(...registerables);

type DailyPrice = {
  coin_name: string;
  coin_symbol: string;
  confidence: number;
  price: number;
  timestamp: string;
};

const LineChart = () => {
  const setLoading = loadingStore((state: any) => state.setLoading);
  const loading = loadingStore((state: any) => state.loading);
  const [dailyPrice, setDailyPrice] = useState<DailyPrice[]>([]);
  const toast = useToast();
  const axiosInstance = configureAxios();

  const getDailyPrice = () => {
    axiosInstance
      .get('https://dev.api.covey.academy//api/token/price/')
      .then((res) => {
        const isStatusOk = res.status === 200;
        if (isStatusOk) {
          setLoading(false);
          setDailyPrice(res.data.slice(-7));
        } else {
          setLoading(false);
          toast({
            title: 'There was an error fetching data',
            status: 'error',
            isClosable: true,
          });
        }
      })
      .catch((err: unknown) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    getDailyPrice();
  }, []);

  function getGradient(ctx: any, chartArea: any) {
    const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);

    gradient.addColorStop(0, 'rgba(59, 135, 247, 0.50)');
    gradient.addColorStop(1, 'rgba(59, 135, 247, 0.00)');

    return gradient;
  }

  const getDate = (timestamps: string[]) => {
    const result = timestamps.map((timestamp) => {
      const result = new Date(timestamp).toLocaleDateString('en-US');
      return result;
    });
    return result;
  };

  const data = {
    labels: getDate(dailyPrice.map((info) => info.timestamp)),
    datasets: [
      {
        label: 'Transaction volume',
        backgroundColor: function (context: { chart: any }) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) return;
          return getGradient(ctx, chartArea);
        },
        borderColor: '#3B87F7',
        borderWidth: 2,
        hoverBackgroundColor: '#3B87F7',
        hoverBorderColor: '#3B87F7',
        data: dailyPrice.map((info) => info.price),
        fill: true,
        lineTension: 0.8,
        pointRadius: 0,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Box>
      <Text className="text-item" color={'#000'} fontSize="20px" fontWeight={700} lineHeight="28px">
        Daily SHIDO Price
      </Text>
      <Box
        className="gsap-item"
        h="auto"
        mt="16px"
        borderRadius={'20px'}
        bg="#353535"
        p={['12px', '12px', '24px']}
        minWidth={'100%'}
      >
        {loading ? (
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        ) : (
          <Line data={data as any} options={options} />
        )}
      </Box>
    </Box>
  );
};
export default LineChart;
