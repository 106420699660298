import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { loadingStore, useLoginStore } from '../../../../store';
import { useForm } from 'react-hook-form';
import { RegisterUserDataType, RegisterUserDataTypePatch } from '../../../../interfaces';
import { CrossIcon } from '../../../../Icons/CrossIcon';

function TokenAddressForm() {
  const toast = useToast();
  const navigate = useNavigate();
  const stepNumber = loadingStore((state: any) => state.stepNumber);
  const setStepNumber = loadingStore((state: any) => state.setStepNumber);
  const userData = useLoginStore((state: any) => state.userData);
  const setLoginDetails = useLoginStore((state: any) => state.setLoginDetails);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RegisterUserDataTypePatch>({
    defaultValues: {
      token_address: '',
    },
  });

  const handleFormSubmit = async (data: RegisterUserDataTypePatch) => {
    axios
      .patch('https://dev.api.covey.academy/api/user/account/', data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      })
      .then((response) => {
        if (response.status === 200) {
          setStepNumber(4);
          toast({
            title: 'Success',
            description: 'Token address successfully added',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'Something went wrong while adding token address',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap="10px"
        justifyContent="center"
        alignItems="center"
        height="inherit"
      >
        <Text
          color="var(--Text-Primary-default, #000)"
          textAlign="center"
          fontSize="30px"
          fontStyle="normal"
          fontWeight=" 700"
          lineHeight="38px" /* 126.667% */
          marginBottom="24px"
        >
          Before we start let’s fill in some data!
        </Text>
        <Box
          borderRadius=" 20px"
          border=" 1px solid var(--colorSplit, rgba(0, 0, 0, 0.06))"
          background=" var(--Cascader-colorWhite, #FFF)"
          /* Light/boxShadowTertiary */
          box-shadow=" 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)"
          display="flex"
          width="401px"
          padding="24px"
          flexDirection="column"
        >
          <Text
            color="rgba(0, 0, 0, 0.88)"
            textAlign="center"
            fontFamily="Poppins"
            fontSize=" 24px"
            fontStyle=" normal"
            fontWeight=" 500"
            lineHeight="24px" /* 100% */
            marginTop="24px"
            marginBottom="24px"
          >
            What is your token address?
          </Text>
          <Box>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <FormControl>
                <FormLabel color={'#fff'} fontSize="12px" fontWeight={600}>
                  What is your token address?
                </FormLabel>
                <Input
                  id="token_address"
                  {...register('token_address', {
                    required: 'Token address is required',
                  })}
                  placeholder="Enter your token address"
                  backgroundColor={'#FFF'}
                  color="#909090"
                  border="1px solid  rgba(0, 0, 0, 0.15) "
                  borderRadius="8px"
                />
              </FormControl>

              <Box display="flex" flexDirection="column" gap="10px">
                <Button
                  background={'#4096FF'}
                  color={'white'}
                  _hover={{
                    bg: '#494949',
                    color: 'white',
                  }}
                  _active={{
                    bg: '#4096FF',
                    color: 'white',
                  }}
                  textTransform="capitalize"
                  fontSize={['14px']}
                  fontWeight={500}
                  fontStyle="normal"
                  width="inherit"
                  transition="0.2s all"
                  mt="24px"
                  w="100%"
                  h="46px"
                  type="submit"
                >
                  Next
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default TokenAddressForm;
