import { Box, Text } from '@chakra-ui/react';
import React from 'react';

interface iProp {
  title: string;
  selected: boolean;
  onClick: () => void;
}

const TimeTab: React.FC<iProp> = ({ title, selected, onClick }) => {
  return (
    <Box
      onClick={onClick}
      style={{
        cursor: 'pointer',
        display: 'flex',
        padding: 'var(--Notification-lineHeightLG, 0px) var(--Button-paddingContentHorizontal, 16px)',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: 'var(--Button-borderRadiusLG, 8px)',
        background: selected ? '#4096FF' : '#FFF',
      }}
    >
      <Text
        onClick={onClick}
        fontWeight={400}
        fontFamily="Poppins"
        fontSize="16px"
        lineHeight={'24px'}
        fontStyle={'normal'}
        color={selected ? 'color: #FFF' : 'var(--colorText, rgba(0, 0, 0, 0.88))'}
      >
        {title}
      </Text>
    </Box>
  );
};

export default TimeTab;
