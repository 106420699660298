import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export const WalletNew = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path d="M6.81041 8.715C6.77291 8.7075 6.72041 8.7075 6.67541 8.715C4.50041 8.6475 2.85791 6.9375 2.85791 4.83C2.85791 2.685 4.60541 0.9375 6.75041 0.9375C8.89541 0.9375 10.6429 2.685 10.6429 4.83C10.6354 6.9375 8.98541 8.6475 6.89291 8.715C6.88541 8.715 6.87791 8.715 6.87041 8.715C6.84791 8.715 6.83291 8.715 6.81041 8.715ZM3.98291 4.83C3.98291 6.33 5.15291 7.5375 6.64541 7.59C6.69041 7.5825 6.78791 7.5825 6.88541 7.59C8.35541 7.5225 9.51041 6.315 9.51791 4.83C9.51791 3.3075 8.27291 2.0625 6.75041 2.0625C5.22791 2.0625 3.98291 3.3075 3.98291 4.83Z" />
    <path d="M12.3378 8.805C12.0303 8.835 11.7153 8.6175 11.6853 8.31C11.6553 8.0025 11.8428 7.725 12.1503 7.6875C12.2403 7.68 12.3378 7.68 12.4203 7.68C13.5153 7.62 14.3703 6.72 14.3703 5.6175C14.3703 4.4775 13.4478 3.555 12.3078 3.555C12.0003 3.5625 11.7453 3.3075 11.7453 3C11.7453 2.6925 12.0003 2.4375 12.3078 2.4375C14.0628 2.4375 15.4953 3.87 15.4953 5.625C15.4953 7.35 14.1453 8.745 12.4278 8.8125C12.4203 8.8125 12.4128 8.8125 12.4053 8.8125C12.3828 8.8125 12.3603 8.8125 12.3378 8.805Z" />
    <path d="M2.81221 15.7875C1.76971 15.0975 1.19971 14.1525 1.19971 13.125C1.19971 12.0975 1.76971 11.145 2.81221 10.4475C5.06221 8.955 8.70721 8.955 10.9422 10.4475C11.9772 11.1375 12.5547 12.0825 12.5547 13.11C12.5547 14.1375 11.9847 15.09 10.9422 15.7875C9.81721 16.5375 8.34721 16.9125 6.87721 16.9125C5.40721 16.9125 3.92971 16.5375 2.81221 15.7875ZM2.32471 13.1325C2.32471 13.77 2.72221 14.385 3.43471 14.8575C5.30221 16.11 8.45221 16.11 10.3197 14.8575C11.0397 14.3775 11.4297 13.7625 11.4297 13.1175C11.4297 12.48 11.0322 11.865 10.3197 11.3925C8.45221 10.1475 5.30221 10.1475 3.43471 11.3925C2.71471 11.8725 2.32471 12.4875 2.32471 13.1325Z" />
    <path d="M13.2076 15.1125C13.1476 14.805 13.3426 14.5125 13.6426 14.445C14.1151 14.3475 14.5501 14.16 14.8876 13.8975C15.3151 13.575 15.5476 13.17 15.5476 12.7425C15.5476 12.315 15.3151 11.91 14.8951 11.595C14.5651 11.34 14.1526 11.16 13.6651 11.0475C13.3651 10.98 13.1701 10.68 13.2376 10.3725C13.3051 10.0725 13.6051 9.87751 13.9126 9.94501C14.5576 10.0875 15.1201 10.3425 15.5776 10.695C16.2751 11.22 16.6726 11.9625 16.6726 12.7425C16.6726 13.5225 16.2676 14.265 15.5701 14.7975C15.1051 15.1575 14.5201 15.42 13.8751 15.5475C13.8301 15.5625 13.7926 15.5625 13.7551 15.5625C13.4926 15.5625 13.2601 15.3825 13.2076 15.1125Z" />
  </Icon>
);
