import axios, { AxiosInstance, AxiosError, InternalAxiosRequestConfig } from 'axios';
// import Cookies from 'js-cookie';

interface ErrorResponse {
  message: string;
  status: number;
}

const configureAxios = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig<any>) => {
      const accessToken = localStorage.getItem('access_token');
      // const sessionId = Cookies.get('session_id');

      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error: AxiosError<ErrorResponse>) => {
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError<ErrorResponse>) => {
      if (error.response) {
        console.error('Error response:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
      return Promise.reject(error);
    },
  );

  return instance;
};

export default configureAxios;
