import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const ArrowIcon = (props: IconProps) => (
  <Icon width="17px" height="16px" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.3125 4H13.1406C13.0609 4 12.9859 4.03906 12.939 4.10313L8.49995 10.2219L4.06089 4.10313C4.01402 4.03906 3.93902 4 3.85933 4H2.68745C2.58589 4 2.52652 4.11563 2.58589 4.19844L8.09527 11.7937C8.29527 12.0687 8.70464 12.0687 8.90308 11.7937L14.4125 4.19844C14.4734 4.11563 14.414 4 14.3125 4Z"
      fill="black"
      fillOpacity="0.88"
    />
  </Icon>
);
