import { Box, Text, Image, useToast } from '@chakra-ui/react';
import React from 'react';

interface PressureInfoProps {
  textColor: string;
  textMain: string;
  amount?: string;
  price: string;
  image: string;
}

export const PressureInfo = (props: PressureInfoProps) => {
  const { textColor, textMain, amount, price, image } = props;
  return (
    <Box
      height={'50%'}
      padding={'16px'}
      border="1px solid #E5E5E5"
      borderRadius={'12px'}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Text color={'#000'} fontSize="20px" fontWeight={600}>
        {textMain}
      </Text>
      <Text color={'#909090'} fontSize="14px" fontWeight={400}>
        {price}
      </Text>
    </Box>
  );
};
