/* eslint-disable prettier/prettier */
import { gsap } from 'gsap';

// SidebarTL----------------------
export function SidebarTL(element) {

  let SidebarTL = gsap.timeline({
    defaults: { duration: 1.5, ease: "power3.out", },
    // repeat: -1, repeatDelay: 0, yoyo: 0,
  });
  SidebarTL
    .from(element.querySelector(".gsap-item-logo"), {
      y: -13, opacity: 0,
    }, "<+=0.00")
    .from(element.querySelectorAll(".gsap-item"), {
      y: 31, opacity: 0, stagger: 0.13
    }, "<+=0.00")
    .from(element.querySelector(".gsap-item-btn"), {
      x: 6,
    }, "<+=0.00")
    .from(element.querySelector(".gsap-item-btn svg"), {
      opacity: 0,
    }, "<+=0.00")

}
// /SidebarTL----------------------