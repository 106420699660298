import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const LeftArrow = (props: IconProps) => (
  <Icon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-1.31134e-06 10C-2.03558e-06 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 -5.87108e-07 10 -1.31134e-06C4.47715 -2.03558e-06 -5.87108e-07 4.47715 -1.31134e-06 10ZM11.472 15.5327C11.7662 15.8243 12.2411 15.8222 12.5327 15.528C12.8243 15.2338 12.8222 14.7589 12.528 14.4673L10.765 12.72C10.0495 12.0109 9.55869 11.5228 9.22659 11.1093C8.90399 10.7076 8.79332 10.4496 8.76387 10.2185C8.74537 10.0734 8.74537 9.92659 8.76387 9.78149C8.79332 9.55042 8.90399 9.2924 9.22659 8.8907C9.55869 8.47718 10.0495 7.98914 10.765 7.28L12.528 5.53269C12.8222 5.24111 12.8243 4.76624 12.5327 4.47204C12.2411 4.17784 11.7662 4.17572 11.472 4.46731L9.6773 6.2461C9.00133 6.91605 8.44886 7.46359 8.05705 7.95146C7.64963 8.45878 7.35469 8.97372 7.27591 9.59184C7.24136 9.86286 7.24136 10.1371 7.27591 10.4082C7.35469 11.0263 7.64963 11.5412 8.05705 12.0485C8.44886 12.5364 9.00133 13.084 9.6773 13.7539L11.472 15.5327Z"
      fill="#353535"
    />
  </Icon>
);
