/* eslint-disable prettier/prettier */
import { gsap } from 'gsap';

// Content_HeaderTL----------------------
export function Content_HeaderTL(element) {

  let Content_HeaderTL = gsap.timeline({
    defaults: { duration: 1.5, ease: "power3.out", },
    // repeat: -1, repeatDelay: 0, yoyo: 0,
  });
  Content_HeaderTL
    .from(element.querySelector(".gsap-item-left"), {
      x: -13, opacity: 0,
    }, "<+=0.00")
    .from(element.querySelectorAll(".gsap-item-right .gsap-item-inner"), {
      x: 13, opacity: 0, stagger: 0.13,
    }, "<+=0.00")

}
// /Content_HeaderTL----------------------