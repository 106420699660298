import React, { useState } from 'react';
import type { UploadProps, UploadFile } from 'antd';
import { Upload, message } from 'antd';
import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { FileUploadIcon } from '../../../../Icons/FileIcon';
import { loadingStore } from '../../../../store';
import axios from 'axios';

const FileUploader: React.FC = () => {
  const toast = useToast();
  const setStepNumber = loadingStore((state: any) => state.setStepNumber);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const uploadProps: UploadProps = {
    name: 'csv_file',
    accept: '.csv',
    beforeUpload: (file) => {
      const isCsv = file.type === 'text/csv';
      if (!isCsv) {
        message.error('You can only upload CSV files!');
      }
      return isCsv || Upload.LIST_IGNORE;
    },
    customRequest: async ({ file, onSuccess, onError }) => {
      const formData = new FormData();
      formData.append('csv_file', file);
      try {
        const response = await axios.post('https://dev.api.covey.academy/api/user/address-book/', formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        onSuccess && onSuccess(response.data);
        toast({
          title: 'Success',
          description: 'File uploaded successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setUploadSuccess(true); // Set upload success state
      } catch (error) {
        onError && onError(new Error('Upload failed'));
        toast({
          title: 'Error',
          description: 'Something went wrong while uploading file',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        console.log('file upload done');
      } else if (status === 'error') {
        console.log('Something went wrong');
      }
      setFileList([...info.fileList]);
    },
  };

  const handleUpload = () => {
    if (fileList.length > 0 && uploadProps.customRequest) {
      uploadProps.customRequest({
        file: fileList[0],
        onSuccess: (response: any) => {
          console.log('Upload successful:', response);
          toast({
            title: 'Success',
            description: 'File uploaded successfully',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          setUploadSuccess(true); // Set upload success state
        },
        onError: (error: Error) => {
          console.error('Upload failed:', error);
          toast({
            title: 'Error',
            description: 'Something went wrong while uploading file',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        },
      } as any);
    }
  };

  return (
    <form>
      <Box
        borderRadius="6px"
        border="1px dashed rgba(0, 0, 0, 0.15)"
        background="rgba(0, 0, 0, 0.03)"
        height="136px"
        display="flex"
        padding="10px"
      >
        <Upload {...uploadProps} fileList={fileList}>
          <Box
            width="321px"
            height="64px"
            justifyContent="center"
            alignItems="center"
            gap="8px"
            display={fileList?.length === 0 ? 'flex' : 'none'}
          >
            <FileUploadIcon />
            <Text
              color="var(--Text-Primary-description, #909090)"
              fontFamily="Poppins"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="22px"
            >
              Upload CSV file
            </Text>
          </Box>
          <Box display={fileList?.length === 0 ? 'flex' : 'none'} marginTop="8px" justifyContent="flex-start">
            <Button
              background={'#4096FF'}
              color={'white'}
              _hover={{
                bg: '#494949',
                color: 'white',
              }}
              _active={{
                bg: '#4096FF',
                color: 'white',
              }}
              textTransform="capitalize"
              fontSize={['14px']}
              fontWeight={500}
              fontStyle="normal"
              transition="0.2s all"
              h="32px"
              width="321px"
              onClick={handleUpload}
            >
              Upload
            </Button>
          </Box>
        </Upload>
      </Box>
      <Box display="flex">
        <Button
          borderRadius="6px"
          border="1px solid rgba(0, 0, 0, 0.15)"
          background={uploadSuccess ? '#2D8DFB' : 'rgba(0, 0, 0, 0.04)'}
          color={'#fff'}
          _hover={{
            bg: uploadSuccess ? '#494949' : '#2D8DFB',
            color: 'white',
          }}
          _active={{
            bg: '#4096FF',
            color: 'white',
          }}
          textTransform="capitalize"
          fontSize={['14px']}
          fontWeight={400}
          fontStyle="normal"
          width="inherit"
          transition="0.2s all"
          mt="24px"
          w="100%"
          h="32px"
          onClick={() => uploadSuccess && setStepNumber(8)}
          isDisabled={!uploadSuccess}
        >
          Next
        </Button>
      </Box>
    </form>
  );
};

export default FileUploader;
