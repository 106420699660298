import React, { useState } from 'react';
import { WalletInformation } from '../../../interfaces/tokenHolderInterface';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { PaginationTable } from 'table-pagination-chakra-ui';
import { TokenInfo } from './TokenInfo';
import Avax from '../../../assets/images/svgs/avax.svg';
import { Spinner, Table, TableContainer, Tbody, Td, Thead, Tr } from '@chakra-ui/react';
import { loadingStore } from '../../../store';

interface TokenHolderMainProps {
  singleTokenHolder: WalletInformation[];
}

export const TokenHolderMain = (props: TokenHolderMainProps) => {
  const { singleTokenHolder } = props;
  const setLoading = loadingStore((state: any) => state.setLoading);
  const loading = loadingStore((state: any) => state.loading);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  return (
    <>
      {' '}
      {loading ? (
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      ) : (
        <TableContainer>
          <Table
            // style={{ borderCollapse: 'separate', borderSpacing: '0 1em' }}
            marginTop={'40px'}
            backgroundColor="#FFF"
            borderRadius={'20px'}
            size="md"
          >
            {/* <Thead border="2px solid red" marginBottom={'16px'}>
              <Tr>
                <TableHeaderText text={'Wallet Address'} />
                <TableHeaderText text={'Balance(SHIDO)'} />
              </Tr>
            </Thead> */}
            <Tbody>
              {singleTokenHolder &&
                singleTokenHolder.length > 0 &&
                singleTokenHolder
                  .map((info) => (
                    <Tr key={info?.id} cursor={'pointer'}>
                      {/* <Td> */}
                      <TokenInfo image={Avax} key={info.id} name={info?.token_name} tokenBalance={info.token_balance} />
                      {/* </Td> */}
                    </Tr>
                  ))
                  .slice(pageSize * pageIndex, pageSize * (pageIndex + 1))}
            </Tbody>
          </Table>
          <PaginationTable
            colorScheme="blue"
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            labelOptions="Items"
            setPageIndex={setPageIndex}
            totalItemsCount={singleTokenHolder.length}
            pageSizeOptions={[5, 10]}
          />
        </TableContainer>
      )}
    </>
  );
};
