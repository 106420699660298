import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const AvaxIcon = (props: IconProps) => (
  <Icon width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1351_15571)">
      <path d="M29.1016 6.89062H6.87402V27.0928H29.1016V6.89062Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9878 18.7143C35.9878 28.6423 27.9344 36.6904 17.9997 36.6904C8.06523 36.6904 0.0117188 28.6423 0.0117188 18.7143C0.0117188 8.78644 8.06523 0.738281 17.9997 0.738281C27.9344 0.738281 35.9878 8.78644 35.9878 18.7143ZM12.9024 25.8679H9.41148C8.67792 25.8679 8.31557 25.8679 8.09464 25.7267C7.85601 25.5721 7.71019 25.3159 7.69251 25.0333C7.67924 24.7728 7.86044 24.4548 8.22278 23.8189L16.8424 8.63566C17.2092 7.99092 17.3948 7.66854 17.629 7.54932C17.8809 7.42126 18.1814 7.42126 18.4333 7.54932C18.6675 7.66854 18.8531 7.99092 19.2198 8.63566L20.9918 11.7269L21.0009 11.7426C21.397 12.4343 21.5979 12.7851 21.6856 13.1532C21.7828 13.5551 21.7828 13.979 21.6856 14.3809C21.5972 14.7518 21.3984 15.1051 20.9962 15.8073L16.4686 23.8056L16.4568 23.8261C16.0581 24.5235 15.856 24.8769 15.5759 25.1436C15.271 25.4351 14.9042 25.647 14.5021 25.7664C14.1353 25.8679 13.7244 25.8679 12.9024 25.8679ZM21.7183 25.8679H26.7205C27.4584 25.8679 27.8297 25.8679 28.0508 25.7224C28.2893 25.5677 28.4395 25.3071 28.4529 25.0246C28.4656 24.7726 28.2884 24.4669 27.9411 23.868C27.9291 23.8476 27.9171 23.8268 27.9049 23.8057L25.3993 19.5222L25.3708 19.474C25.0187 18.879 24.8409 18.5785 24.6127 18.4623C24.3609 18.3343 24.0647 18.3343 23.8129 18.4623C23.5831 18.5816 23.3975 18.8951 23.0307 19.5266L20.534 23.8101L20.5255 23.8249C20.16 24.4554 19.9773 24.7704 19.9905 25.0289C20.0082 25.3116 20.154 25.5721 20.3926 25.7267C20.6092 25.8679 20.9803 25.8679 21.7183 25.8679Z"
        fill="#353535"
      />
    </g>
    <defs>
      <clipPath id="clip0_1351_15571">
        <rect width="36" height="36" fill="white" transform="translate(0 0.714844)" />
      </clipPath>
    </defs>
  </Icon>
);
