import { AddIcon } from '@chakra-ui/icons';
import { Box, HStack, Input, Tag, TagCloseButton, TagLabel, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { TagType } from '../../../interfaces/tokenHolderInterface';
import { getTagsColor } from '../../../utils/helper';

interface TagRowProps {
  label: string[];
  id: number;
  setReTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  reTrigger: boolean;
  updateTag: (id: number, labels: string[]) => Promise<void>;
  deleteTag: (id: number, label: string) => Promise<void>;
}

export const TagRow: React.FC<TagRowProps> = ({ label, id, setReTrigger, reTrigger, updateTag, deleteTag }) => {
  const toast = useToast();
  const [tags, setTags] = useState<TagType[]>([]);
  const [newTagText, setNewTagText] = useState('');
  const [renderInputField, setRenderInputField] = useState(false);

  const addTag = async () => {
    if (newTagText.trim()) {
      const updatedLabels = [...label, newTagText.trim()];
      try {
        await updateTag(id, updatedLabels);
        setNewTagText('');
        setRenderInputField(false);
        setReTrigger(!reTrigger);
        toast({
          title: 'Tag added successfully',
          status: 'success',
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error adding tag',
          status: 'error',
          isClosable: true,
        });
        console.error(error);
      }
    }
  };

  const removeTag = async (tagToRemove: string) => {
    try {
      await deleteTag(id, tagToRemove);
      setReTrigger(!reTrigger);
      toast({
        title: 'Tag removed successfully',
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error removing tag',
        status: 'error',
        isClosable: true,
      });
      console.error(error);
    }
  };

  const toggleAddTag = () => {
    setRenderInputField(!renderInputField);
  };

  useEffect(() => {
    if (label && label.length > 0) {
      setTags(getTagsColor(label));
    } else {
      setTags([]);
    }
  }, [label]);

  return (
    <Box
      marginRight="24px"
      display={['none', 'none', 'none', 'flex', 'flex']}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <AddIcon cursor={'pointer'} color="#909090" onClick={toggleAddTag} />
      <HStack spacing={4}>
        {tags &&
          tags.map((tag, index) => (
            <Tag
              borderRadius={'8px'}
              border={`1px solid ${tag.borderColor}`}
              size={tag.size}
              key={`${tag.key}-${index}`}
              variant="solid"
              backgroundColor={tag.bgColor}
            >
              <TagLabel color={tag.textColor} fontSize={'14px'}>
                {tag.text}
              </TagLabel>
              <TagCloseButton color="#909090" onClick={() => removeTag(tag.text)} />
            </Tag>
          ))}
      </HStack>
      {renderInputField && (
        <Input
          border="2px solid red"
          color="black"
          backgroundClip={'red'}
          width="40%"
          placeholder="Add a tag"
          value={newTagText}
          onChange={(e) => setNewTagText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              addTag();
            }
          }}
        />
      )}
    </Box>
  );
};
