import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const CheckBoxIcon = (props: IconProps) => (
  <Icon width="17px" height="32px" viewBox="0 0 17 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" width="16" height="32" rx="6" fill="white" />
    <rect x="0.5" y="8" width="16" height="16" rx="4" fill="#2D8DFB" />
    <path
      d="M4.41308 15.0352H4.41326C4.49256 15.0353 4.57081 15.0533 4.64213 15.088C4.71341 15.1226 4.77591 15.173 4.82497 15.2352L4.41308 15.0352ZM4.41308 15.0352H3.59394C3.39031 15.0352 3.2766 15.2698 3.40232 15.4292L3.40237 15.4293L6.61214 19.4957L6.61218 19.4957C6.82247 19.7619 7.22568 19.7611 7.43667 19.4962L7.43708 19.4957L13.1597 12.244C13.1598 12.2438 13.16 12.2436 13.1602 12.2434C13.2884 12.0829 13.1699 11.85 12.9689 11.85H12.1498C11.9894 11.85 11.8367 11.9235 11.7376 12.0504C11.7375 12.0506 11.7374 12.0507 11.7373 12.0508L7.02403 18.0216M4.41308 15.0352L7.02403 18.0216M7.02403 18.0216L4.82506 15.2353L7.02403 18.0216Z"
      fill="white"
      stroke="white"
      strokeWidth="0.3"
    />
  </Icon>
);
