import React, { useState } from 'react';
import { DatePicker, Space, Button, TimeRangePickerProps, message, Table } from 'antd';
import axios from 'axios';
import moment from 'moment';

const { RangePicker } = DatePicker;

function TxInfo() {
  const [dateRange, setDateRange] = useState<moment.Moment[]>([]);
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState<string | null>(null);

  const handleDateChange = (dates: TimeRangePickerProps['value'] | null) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setDateRange(
        [start ? moment(start.toISOString()) : null, end ? moment(end.toISOString()) : null].filter(
          Boolean,
        ) as moment.Moment[],
      );
    } else {
      setDateRange([]);
    }
  };

  const fetchData = async () => {
    if (dateRange.length !== 2) {
      message.error('Please select both start and end dates');
      return;
    }

    const startDate = dateRange[0].format('YYYY-MM-DD');
    const endDate = dateRange[1].format('YYYY-MM-DD');

    const accessToken = localStorage.getItem('access_token');

    if (!accessToken) {
      message.error('Access token not found. Please log in again.');
      // Optionally, redirect to login page here
      return;
    }

    try {
      const response = await axios.get(
        //update this URL to one that's Live
        `https://dev.api.covey.academy/api/user/inflow-outflow-by-time/?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      setResponseData(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError((error as any).response?.data?.detail || 'An error occurred while fetching data');
      setResponseData(null);

      if ((error as any).response?.status === 401) {
        message.error('Your session has expired. Please log in again.');
        // Optionally, redirect to login page here
      }
    }
  };

  const formatAmount = (value: number) => {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const columns = [
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (value: number) => formatAmount(value),
    },
    {
      title: 'Type',
      dataIndex: 'is_receiver',
      key: 'is_receiver',
      render: (value: boolean) => (value ? 'Receiver' : 'Sender'),
    },
  ];

  const tableData = responseData
    ? Object.entries(responseData).map(([key, value]: [string, any], index) => ({
        key: index,
        address: key,
        total_amount: value.total_amount,
        is_receiver: value.is_receiver,
      }))
    : [];

  return (
    <Space direction="vertical" size={12}>
      <RangePicker onChange={(dates, dateStrings) => handleDateChange(dates)} />
      <Button onClick={fetchData}>Fetch Data</Button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {responseData && <Table columns={columns} dataSource={tableData} />}
    </Space>
  );
}

export default TxInfo;
