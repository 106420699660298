import { Box, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';
import React from 'react';
import LogInForm from '../components/logIn/loginForm/LoginForm';
import DashBoardImage from '../assets/images/svgs/dasboardImage.svg';
function Login() {
  return (
    <Box
      height={[
        'calc(100vh - 48px)',
        'calc(100vh - 120px)',
        'calc(100vh - 120px)',
        'calc(100vh - 48px)',
        'calc(100vh - 48px)',
      ]}
      display={'flex'}
      gap="24px"
      margin={['18px', '60px', '60px', '24px', '24px']}
    >
      <Box
        w={['370px', '450px', '500px']}
        bg="#353535"
        borderRadius={'20px'}
        display={['none', 'none', 'none', 'block', 'block']}
      >
        <LogInForm />
      </Box>
      <Box
        className="login-side-container"
        borderRadius={'20px'}
        bg={['white', 'white', 'white', 'rgba(64, 150, 255, 0.10)', 'rgba(64, 150, 255, 0.10)']}
        w="100%"
        p={['0px', '10px', '40px']}
        display="flex"
        alignItems="center"
        flexDirection={'column'}
      >
        <Box m="auto" display="flex" alignItems="center" flexDirection={'column'}>
          <Text textAlign={['center']} color={'#000000'} fontSize={['24px', '24px', '46px']} fontWeight={700}>
            Easily Manage Your Portfolio with{' '}
            <span color="#4096FF !important" className="logo-text">
              Covey Insight
            </span>
          </Text>
          <Text color={'#000000'} fontSize="14px" fontWeight={600} mt="24px">
            Take Charge
            <span color="#4096FF !important" className="logo-text">
              Now!
            </span>
          </Text>
          <Image display={['none', 'none', 'none', 'block', 'block']} mt="64px" src={DashBoardImage} alt="logo" />
          <Box
            display={['block', 'block', 'block', 'none', 'none']}
            bg={['#353535', '#353535']}
            mt="24px"
            w={['100%', '100%', '70%']}
            borderRadius="20px"
          >
            <LogInForm />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default Login;
