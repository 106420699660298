import { Modal, ModalProps } from 'antd';
import React from 'react';

type Props = Omit<ModalProps, 'styles'> & {
  styles?: {
    body?: React.CSSProperties;
    content?: React.CSSProperties;
    mask?: React.CSSProperties;
  };
};

const AppModal: React.FC<Props> = ({ styles, ...props }) => {
  return (
    <Modal
      centered
      title={null}
      footer={null}
      closeIcon={null}
      styles={{
        body: {
          display: 'flex',
          flexDirection: 'column',
          ...styles?.body,
        },
        content: {
          width: 'fit-content',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '20px',
          background: 'var(--Cascader-colorWhite, #FFF)',
          boxShadow: '0px 1.737px 5.211px 0px rgba(0, 0, 0, 0.15)',
          padding: '24px',
          ...styles?.content,
        },
        mask: {
          backdropFilter: 'blur(4px)',
          backgroundColor: '#000000BF',
          ...styles?.mask,
        },
      }}
      {...props}
    />
  );
};

export default AppModal;
