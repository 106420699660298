import { Button, Row } from 'antd';
import React from 'react';
import Cross from '../../assets/images/svgs/cross.svg';
import AppModal from './AppModal';

interface IProps {
  showModal: boolean;
  isShowModal: (e: boolean) => void;
  onConfirmDelete: () => void;
}

const DeleteModal: React.FC<IProps> = ({ showModal, isShowModal, onConfirmDelete }) => {
  return (
    <AppModal open={showModal} onCancel={() => isShowModal(false)} onClose={() => isShowModal(false)}>
      <Row
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '24px',
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: '329px',
            width: '100%',
          }}
        >
          <span
            style={{
              color: 'rgba(0, 0, 0, 0.88)',
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
            }}
          >
            Delete your entry
          </span>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              isShowModal(false);
            }}
          >
            <img src={Cross} />
          </div>
        </Row>
        <span
          style={{
            maxWidth: '326px',
            width: '100%',
            color: 'var(--Text-Primary-description, #909090)',
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
          }}
        >
          Are you sure you want to delete this entry? This action cannot be undone.
        </span>

        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '12px',
            alignSelf: 'stretch',
          }}
        >
          <Button
            onClick={() => isShowModal(false)}
            style={{
              display: 'flex',
              padding: 'var(--Notification-lineHeightLG, 0px) var(--Button-paddingContentHorizontal, 16px)',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              flex: '1 0 0',
              borderRadius: '6px',
              border: '1px solid rgba(0, 0, 0, 0.15)',
              background: 'var(--Button-colorBgContainer, #FFF)',
              color: 'rgba(0, 0, 0, 0.88)',
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              onConfirmDelete();
              isShowModal(false);
            }}
            style={{
              display: 'flex',
              padding: 'var(--Notification-lineHeightLG, 0px) var(--Button-paddingContentHorizontal, 16px)',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              flex: '1 0 0',
              borderRadius: '6px',
              border: '1px solid rgba(0, 0, 0, 0.15)',
              background: 'var(--Button-colorError, #FF4D4F)',
              color: 'var(--Button-colorTextLightSolid, #FFF)',
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
            }}
          >
            Delete
          </Button>
        </div>
      </Row>
    </AppModal>
  );
};

export default DeleteModal;
