import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChartSanki } from '../../Icons/ChartSanki';
import { Data } from '../../Icons/Data';
import { HomeIcon } from '../../Icons/HomeIcon';
import { Logout } from '../../Icons/LogoutIcon';
import { Portfolio } from '../../Icons/PortFolio';
import { SettingsWheel } from '../../Icons/SettingWheel';
import { WalletNew } from '../../Icons/WalletNew';
import { OverlayProps, SidebarOption } from '../../interfaces';
import Header from './Header';
import Sidebar from './Sidebar';
import { loadingStore, useLoginStore } from '../../store';
import LoginStepsMain from '../logIn/loginForm/loginSteps/LoginStepsMain';
import axios from 'axios';
import { Settings } from '../../Icons/Settings';
import Icon from '../../assets/images/pngs/Icons.png';

function Overlay({ children }: OverlayProps) {
  const location = useLocation();
  const [activeView, setActiveView] = useState('dashboard');
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const userData = useLoginStore((state: any) => state.userData);
  const stepNumber = loadingStore((state: any) => state.stepNumber);
  const setLoginDetails = useLoginStore((state: any) => state.setLoginDetails);

  useEffect(() => {
    const activeSidebarOption = sidebarOptions.find(
      (sidebarOption: SidebarOption) => sidebarOption.href === location.pathname,
    );

    activeSidebarOption ? setActiveView(activeSidebarOption.key) : setActiveView('');
  }, [location.pathname]);

  const sidebarOptions: SidebarOption[] = [
    {
      href: '/',
      key: 'dashboard',
      text: 'Dashboard',
      sideBarIcon: <HomeIcon stroke="currentColor" fill="currentColor" boxSize={[4]} />,
    },
    {
      href: '/tokenHolders',
      key: 'tokenHolders',
      text: 'Token Holders',
      sideBarIcon: <Portfolio stroke="currentColor" boxSize={[4]} />,
    },
    // {
    //   href: '/marketOverview',
    //   key: 'marketOverview',
    //   text: 'Market Overview',
    //   sideBarIcon: <Overview stroke="currentColor" boxSize={[4]} />,
    // },
    // {
    //   href: '/wallet',
    //   key: 'wallet',
    //   text: 'Wallet',
    //   sideBarIcon: <Wallet fill="currentColor" boxSize={[4]} />,
    // },
    {
      href: '/transactions',
      key: 'transactions',
      text: 'Transactions',
      sideBarIcon: <WalletNew fill="currentColor" boxSize={[4]} />,
    },

    // { href: '/reports', key: 'reports', text: 'Reports', sideBarIcon: <Report fill="currentcolor" boxSize={4} /> },
    {
      href: '/sankey',
      key: 'sankey',
      text: 'Sankey',
      sideBarIcon: <Data fill="currentcolor" boxSize={4} />,
    },
    {
      href: '/demo',
      key: 'demo',
      text: 'Demo',
      sideBarIcon: <ChartSanki fill="currentcolor" boxSize={4} />,
    },
    // {
    //   href: '/helpCenter',
    //   key: 'helpCenter',
    //   text: 'Help Center',
    //   sideBarIcon: <Help stroke="currentColor" boxSize={[4]} />,
    // },
    {
      href: '/addressBooks',
      key: 'addressBooks',
      text: 'Address Books',
      sideBarIcon: <img src={Icon} alt="icon" />,
    },
    {
      href: '/settings',
      key: 'settings',
      text: 'Settings',
      sideBarIcon: <SettingsWheel fill="currentColor" boxSize={[4]} />,
    },
    {
      href: '/',
      key: 'logout',
      text: 'Logout',
      sideBarIcon: <Logout fill="currentColor" boxSize={[4]} />,
    },
  ];

  useEffect(() => {
    axios
      .get('https://dev.api.covey.academy/api/user/current/', {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      })
      .then((response) => {
        setLoginDetails(response?.data?.data);
      })
      .catch((err: any) => {
        console.log('Error fetching user data:', err);
      });
  }, [stepNumber]);

  console.log('userData', userData, stepNumber);

  return (
    <>
      {!userData?.token_address || userData?.token_address === '' ? (
        <Box w="100%" h="100vh" display="flex" flexDirection="column" backgroundColor="#DADCDE">
          <LoginStepsMain />
        </Box>
      ) : stepNumber === 2 ||
        stepNumber === 3 ||
        stepNumber === 4 ||
        stepNumber === 5 ||
        stepNumber === 6 ||
        stepNumber === 7 ||
        stepNumber === 8 ? (
        <Box w="100%" h="100vh" display="flex" flexDirection="column" backgroundColor="#DADCDE">
          <LoginStepsMain />
        </Box>
      ) : (
        <Box w="100%" h="100vh" display="flex" flexDirection="column">
          <Box className="overlay-wrap" display="flex" flex="1">
            <Sidebar
              sideBarOpen={sideBarOpen}
              activeView={activeView}
              sidebarOptions={sidebarOptions}
              setActiveView={(view) => setActiveView(view)}
              setSideBarOpen={(state) => setSideBarOpen(state)}
            />

            <Box
              className="content-wrap"
              flex="1"
              width={['100%']}
              height={['calc(100vh - 24px)', 'calc(100vh - 48px)', 'calc(100vh - 48px)']}
              display="flex"
              flexDirection="column"
              padding={['12px', '12px', '30px', '30px', '30px']}
              backgroundColor="#F6F6F6"
              marginTop={['12px', '12px', '24px', '24px', '24px', '24px']}
              marginRight={['12px', '12px', '24px', '24px', '24px', '24px']}
              marginBottom={['12px', '12px', '24px', '24px', '24px', '24px']}
              marginLeft={['12px', '12px', '0px', '0px', '0px', '0px']}
              borderRadius={'20px'}
              overflowX={['scroll', 'scroll', 'scroll', 'scroll', 'scroll']}
            >
              <Header
                sideBarOpen={sideBarOpen}
                activeView={activeView}
                sidebarOptions={sidebarOptions}
                setActiveView={(view) => setActiveView(view)}
                setSideBarOpen={(state) => setSideBarOpen(state)}
              />
              {children}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default Overlay;
