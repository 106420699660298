import { Box, Divider, Text } from '@chakra-ui/react';
import React from 'react';
import { CheckBoxIcon } from '../../../../Icons/CheckBox';
import { ArrowIcon } from '../../../../Icons/ArrowDown';
import { CheckBoxContainer } from '../../../../Icons/CheckboxContainer';

interface AddressDropDown {
  setShowForm: (showForm: boolean) => void;
  showForm: boolean;
  title: string;
  address: string;
  isReceiver: boolean;
}

export default function AddressDropDown(props: AddressDropDown) {
  const { setShowForm, showForm, title, address, isReceiver } = props;
  return (
    <Box marginTop="8px">
      <Box
        padding="8px 0px"
        marginBottom="8px"
        display="flex"
        alignItems="center"
        height="22px"
        justifyContent="space-between"
      >
        <Box display="flex" justifyContent="center" alignItems="center" gap="8px">
          <Box cursor="pointer">{isReceiver ? <CheckBoxIcon /> : <CheckBoxContainer />}</Box>

          <Text
            color=" var(--Text-Primary-default, #000)"
            fontSize="14px"
            fontStyle=" normal"
            fontWeight="500"
            lineHeight="22px"
          >
            {title}
          </Text>
        </Box>
        <Box
          cursor="pointer"
          // onClick={() => setShowForm(!showForm)}
        >
          <ArrowIcon />
        </Box>
      </Box>
      <Divider />
    </Box>
  );
}
