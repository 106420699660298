import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import CoveyLogoWhite from '../../assets/images/svgs/coveyLogoWhite.svg';
import CoveyLogoTextWhite from '../../assets/images/svgs/coveyLogoTextWhite.svg';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { loadingStore, useLoginStore } from '../../store';
import { useForm } from 'react-hook-form';
import { RegisterUserDataType } from '../../interfaces';

function Step1() {
  const toast = useToast();
  const navigate = useNavigate();
  const stepNumber = loadingStore((state: any) => state.stepNumber);
  const setStepNumber = loadingStore((state: any) => state.setStepNumber);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RegisterUserDataType>({
    defaultValues: {
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
    },
  });

  const handleFormSubmit = async (data: RegisterUserDataType) => {
    axios
      .post('https://dev.api.covey.academy/api/user/account/create/', data)
      .then((response) => {
        if (response) {
          console.log('response', response);
          navigate('/');
          toast({
            title: 'Success',
            description: 'User registered successfully',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch(() => {
        toast({
          title: 'Signup error',
          description: 'Something went wrong while registering user',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box marginTop="10px" display="flex" flexDirection="column" gap={['8px', '8px', '10px']}>
        <FormControl>
          <FormLabel color={'#fff'} fontSize="16px" fontWeight={600}>
            User Name
          </FormLabel>
          <Input
            id="username"
            {...register('username', {
              required: 'User name is required',
            })}
            placeholder="Enter your user name"
            backgroundColor={'#494949'}
            color="#909090"
            border={'none'}
            borderRadius="12px"
          />
        </FormControl>
        <FormControl>
          <FormLabel color={'#fff'} fontSize="16px" fontWeight={600}>
            First Name
          </FormLabel>
          <Input
            id="first_name"
            {...register('first_name', {
              required: 'First name is required',
            })}
            placeholder="Enter your first name"
            backgroundColor={'#494949'}
            color="#909090"
            border={'none'}
            borderRadius="12px"
          />
        </FormControl>

        <FormControl>
          <FormLabel color={'#fff'} fontSize="16px" fontWeight={600}>
            Last name
          </FormLabel>
          <Input
            placeholder="Enter your last name"
            id="last_name"
            {...register('last_name', {
              required: 'Last name is required',
            })}
            backgroundColor={'#494949'}
            color="#909090"
            border={'none'}
            borderRadius="12px"
          />
        </FormControl>

        <FormControl>
          <FormLabel color={'#fff'} fontSize="16px" fontWeight={600}>
            Email
          </FormLabel>
          <Input
            id="email"
            {...register('email', {
              required: 'Email is required',
            })}
            type="email"
            placeholder="Enter your email address"
            backgroundColor={'#494949'}
            color="#909090"
            border={'none'}
            borderRadius="12px"
          />
        </FormControl>

        <FormControl>
          <FormLabel color={'#fff'} fontSize="16px" fontWeight={600}>
            Password
          </FormLabel>
          <InputGroup backgroundColor={'#494949'} border={'none'}>
            <InputRightElement></InputRightElement>
            <Input
              id="password"
              {...register('password', {
                required: 'Password is required',
              })}
              type="password"
              placeholder="Enter your password"
              backgroundColor={'#494949'}
              color="#909090"
              border={'none'}
              borderRadius="12px"
            />
          </InputGroup>
        </FormControl>
      </Box>

      <Button
        background={'#4096FF'}
        color={'white'}
        _hover={{
          bg: '#494949',
          color: 'white',
        }}
        _active={{
          bg: '#4096FF',
          color: 'white',
        }}
        textTransform="capitalize"
        fontSize={['14px']}
        fontWeight={500}
        fontStyle="normal"
        width="inherit"
        transition="0.2s all"
        mt="24px"
        w="100%"
        h="46px"
        type="submit"
      >
        <span>Signup</span>
      </Button>
    </form>
  );
}
export default Step1;
