import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Portfolio = (props: IconProps) => (
  <Icon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.66698 3.99984C4.66698 2.15889 6.15937 0.666504 8.00032 0.666504C9.84127 0.666504 11.3337 2.15889 11.3337 3.99984V4.00764M4.66698 3.99984C5.08552 3.99189 5.55546 3.99984 6.10721 3.99984H9.89342C10.4417 3.99984 10.917 3.99984 11.3337 4.00764M4.66698 3.99984C3.52108 4.0216 2.81247 4.11091 2.26164 4.41501C1.6209 4.76875 1.11496 5.3232 0.821015 5.99211M0.821015 5.99211L3.20538 8.37647C5.4276 10.5987 6.53871 11.7098 7.91942 11.7098C9.30014 11.7098 10.4112 10.5987 12.6335 8.37647L15.0553 5.95468C15.079 5.93093 15.1052 5.90978 15.1334 5.89157V5.89157M0.821015 5.99211C0.811962 6.01271 0.80311 6.03342 0.794461 6.05424C0.464872 6.84751 0.578748 7.87239 0.806498 9.92214C0.99291 11.5999 1.08612 12.4387 1.47311 13.0718C1.81402 13.6294 2.31134 14.0745 2.90324 14.3518C3.57515 14.6665 4.41917 14.6665 6.10721 14.6665H9.89342C11.5815 14.6665 12.4255 14.6665 13.0974 14.3518C13.6893 14.0745 14.1866 13.6294 14.5275 13.0718C14.9145 12.4387 15.0077 11.5999 15.1941 9.92214C15.4219 7.87239 15.5358 6.84751 15.2062 6.05424C15.1833 5.99926 15.1591 5.94502 15.1334 5.89157M11.3337 4.00764C12.4843 4.02918 13.1869 4.1102 13.739 4.41501C14.3473 4.75087 14.8342 5.26766 15.1334 5.89157"
      strokeWidth="1"
    />
  </Icon>
);
