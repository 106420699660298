import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Image, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { gsap } from 'gsap';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { GoBell } from 'react-icons/go';
import { IoPersonOutline } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import { Content_HeaderTL } from '../../assets/animation/Content_Header';
import CoveyInsightMobileLogo from '../../assets/images/svgs/coveyInsightMobileLogo.svg';
import HamBurgerMenuIconMobile from '../../assets/images/svgs/hamBurgerMenuIconMobile.svg';
import CoveySymbol from '../../assets/images/svgs/logoSymbol.svg';
import logoTextDark from '../../assets/images/svgs/logoTextDark.svg';
import { Account } from '../../Icons/AccountIcon';
import { SidebarProps } from '../../interfaces';

interface DropDownMenuProps {
  mobileView?: boolean;
}

interface MenuOptions {
  key: string;
  text: string;
}

const DropDownMenu = ({ mobileView }: DropDownMenuProps) => {
  const menuOptions: MenuOptions[] = [
    {
      key: 'dashboard',
      text: 'Dashboard',
    },
    {
      key: 'portfolio',
      text: 'Portfolio',
    },
    {
      key: 'marketOverview',
      text: 'Market Overview',
    },
    {
      key: 'wallet',
      text: 'Wallet',
    },
    {
      key: 'transactions',
      text: 'Transactions',
    },
    {
      key: 'reports',
      text: 'Reports',
    },
    {
      key: 'insight',
      text: 'Insight',
    },
  ];

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          {mobileView ? (
            <MenuButton
              h={['30px', '30px', '44px', '44px', '44px']}
              w={['30px', '30px', '44px', '44px', '44px']}
              borderRadius="50%"
              backgroundColor="#353535"
              display={['flex', 'flex', 'none']}
              position="relative"
            >
              <Account position="absolute" top="3px" right={'-17px'} />
            </MenuButton>
          ) : (
            <MenuButton
              as={Button}
              _hover={{
                bg: '#494949',
                color: 'white',
              }}
              _active={{
                bg: '#4096FF',
                color: 'white',
              }}
              _focus={{
                bg: '#4096FF',
                color: 'white',
              }}
              display={['none', 'none', 'block', 'flex', 'flex']}
              leftIcon={<IoPersonOutline color="white" style={{ strokeWidth: '20', color: 'white', fill: 'white' }} />}
              backgroundColor="#353535"
              variant="solid"
              rightIcon={isOpen ? <ChevronUpIcon color="white" /> : <ChevronDownIcon color="white" />}
              borderRadius={'12px'}
              border="1px solid #494949"
              fontSize="16px"
              fontStyle={'normal'}
              fontWeight="500px"
              color="#909090"
            >
              <span style={{ color: 'white' }}>Account</span>
            </MenuButton>
          )}
          <MenuList bg="#353535" borderRadius="10px">
            {menuOptions.map((sideBarOption: MenuOptions) => (
              <MenuItem
                key={sideBarOption.key}
                _hover={{
                  bg: '#494949',
                  color: 'white',
                }}
                color="#909090"
                zIndex={1}
              >
                {sideBarOption?.text}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

function Header({ sideBarOpen, setSideBarOpen }: SidebarProps) {
  const location = useLocation();
  const Content_HeaderRef = useRef<HTMLDivElement>(null);
  const [isTokenHolderPage, setIsTokenHolderPage] = useState(false);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      Content_HeaderTL(Content_HeaderRef.current);
    }, Content_HeaderRef);

    return () => ctx.revert();
  }, [Content_HeaderRef]);

  useEffect(() => {
    if (pathname === '/tokenHolders') {
      setIsTokenHolderPage(true);
    }
  }, []);

  const { pathname } = location;

  return (
    <Box
      minW={['unset', 'unset', 'unset', 'unset', '1070px']}
      height={['44px']}
      w={['100%']}
      borderBottom="1px solid rgba(242, 243, 245, 0.14)"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      ref={Content_HeaderRef}
      zIndex={1}
      className="content-header"
    >
      <Box className="gsap-item-left" display="flex" alignItems="center" gap={['8px', '16px', '16px', '16px', '16px']}>
        <Box
          h={['30px', '44px', '44px', '44px', '44px']}
          w={['30px', '44px', '44px', '44px', '44px']}
          borderRadius="50%"
          backgroundColor="#FFFFFF"
          display={['flex', 'flex', 'none', 'none', 'none']}
          justifyContent="center"
          alignItems="center"
          onClick={() => setSideBarOpen(!sideBarOpen)}
          cursor="pointer"
        >
          <Image
            h={['22px', '22px', '44px', '44px', '44px']}
            w={['22px', '22px', '44px', '44px', '44px']}
            src={HamBurgerMenuIconMobile}
            alt="logo"
          />
        </Box>
        <Image
          h={['64px', '64px', '0', '0', '0']}
          w={['64px', '64px', '0', '0', '0']}
          src={CoveyInsightMobileLogo}
          alt="logo"
        />
        <Image
          h={['0', '0', '44px', '44px', '44px']}
          w={['0', '0', '44px', '44px', '44px']}
          src={CoveySymbol}
          alt="logo"
        />
        <Image display={['none', 'none', 'block', 'block', 'block']} src={logoTextDark} alt="logoText" />

        {isTokenHolderPage && (
          <>
            <Box borderLeft={'1px solid #000000'} height="32px"></Box>
            <Box>
              <Text fontSize={'20px'} fontWeight="700" color="black">
                Token Holders
              </Text>
            </Box>
          </>
        )}
      </Box>
      <Box
        className="gsap-item-right"
        display="flex"
        // h="280px"
        alignItems="center"
        gap={['8px', '16px', '16px', '16px', '16px']}
      >
        {/* <div className="gsap-item-inner"> */}
        <Box
          h={['22px', '44px', '44px', '44px', '44px']}
          w={['22px', '44px', '44px', '44px', '44px']}
          borderRadius="50%"
          backgroundColor="#FFFFFF"
          display={'flex'}
          justifyContent="center"
          alignItems="center"
        >
          <GoBell color="black" />
        </Box>
        {/* </div> */}
        {/* <div className="gsap-item-inner"> */}
        <DropDownMenu />
        <DropDownMenu mobileView={true} />
        {/* </div> */}
      </Box>
    </Box>
  );
}

export default Header;
