export const makeDrawerItem = (scene: string, heading: string, subHeading: string) => {
  return {
    scene: scene,
    heading: heading,
    subHeading: subHeading,
  };
};

export const getOpSys = () => {
  let os = '';

  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'IOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};

export const isValidUrl = (urlString: string) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const getDate = (timestamps: number[] | undefined) => {
  const result =
    timestamps &&
    timestamps.map((timestamp) => {
      const result = new Date(timestamp).toLocaleDateString('en-US');
      return result;
    });
  return result;
};

export const numberWithCommas = (number: string) => {
  const numberToBeConverted = Number(number);
  return numberToBeConverted.toLocaleString('en-US', { style: 'decimal' });
};

export const formatDate = (dateString: string) => {
  // const dateString = '2024-01-14 11:45:23.000 UTC';
  const dateObject = new Date(dateString);
  const options: any = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const formattedDate = dateObject.toLocaleDateString('en-US', options);
  return formattedDate;
};

export const unixTimeStampConversion = (timeStamp: number) => {
  // Create a new Date object with the timestamp (multiply by 1000 because JavaScript works with milliseconds)
  const date = new Date(timeStamp * 1000);

  // Create an Intl.DateTimeFormat object with options for formatting
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC', // Adjust the timeZone if necessary
  });

  // Format the date using the formatter
  const formattedDate = formatter.format(date);
  return formattedDate;
};

export const getTagsColor = (label: string[]) => {
  const borderColors = ['#6AD79F', '#B37FEB', '#FFE58F'];
  const bgColors = ['#EFFBF5', '#F9F0FF', '#FFFBE6'];
  const textColors = ['#39CA7F', '#9254DE', '#FFC53D'];
  const mappedArr = label?.map((lab: any, index: any) => {
    const borderColorIndex = index % borderColors.length;
    const bgColorIndex = index % bgColors.length;
    const textColorIdex = index % textColors.length;
    return {
      size: 'md',
      key: index + 1,
      text: lab,
      borderColor: borderColors[borderColorIndex],
      bgColor: bgColors[bgColorIndex],
      textColor: textColors[textColorIdex],
    };
  });
  return mappedArr;
};
