import { Box, Image, Spinner, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import questionMarkIcon from '../../../assets/images/svgs/questionMarkIcon.svg';
import mexShido from '../../../assets/images/svgs/mexShido.svg';
import uniSwapShido from '../../../assets/images/svgs/uniSwapShido.svg';
import configureAxios from '../../../AxiosClient';
import { loadingStore } from '../../../store';
import { MexcInfo, StatsProps, UniSwapInfo } from '../../../interfaces';

export const Stats = (props: StatsProps) => {
  const { img, heading, amount, percentage } = props;
  return (
    <Box
      height={['44px']}
      w={['100%']}
      borderBottom="1px solid rgba(242, 243, 245, 0.14)"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bg="white"
      mt="9px"
      h="65px"
      borderRadius={'12px'}
      padding="10px 15px"
      className="gsap-item"
    >
      <Box display="flex" gap={'10px'} alignItems="center">
        <Image src={img} alt="logo" />
        <Text color={'#000'} fontSize="14px" fontWeight={600}>
          {heading}
        </Text>
      </Box>
      <Box display="flex" gap={'16px'}>
        <Text color={'#909090'} fontSize="12px" fontWeight={400}>
          {amount}
        </Text>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        gap={'16px'}
        borderRadius="12px"
        bg={Math.sign(percentage) === -1 ? '#FFCCCB' : '#EFFBF5'}
        padding="4px 10px"
      >
        <Text color={Math.sign(percentage) === -1 ? 'red' : '#39CA7F'} fontSize="14px" fontWeight={400}>
          {`${percentage}%`}
        </Text>
      </Box>
    </Box>
  );
};

function CurrentPrice() {
  const axiosInstance = configureAxios();
  const setLoading = loadingStore((state: any) => state.setLoading);
  const setMexcData = loadingStore((state: any) => state.setMexcData);
  const loading = loadingStore((state: any) => state.loading);
  const mexcInfo = loadingStore((state: any) => state.mexcInfo);
  // const [mexcInfo, setMexcInfo] = useState<MexcInfo>();
  const [uniSwapInfo, setUniSwapInfo] = useState<UniSwapInfo>();

  const getMexcData = async () => {
    await axiosInstance
      .get('https://dev.api.covey.academy/api/token/mexc/')
      .then((res) => {
        const isStatusOk = res.status === 200;
        if (isStatusOk) {
          setLoading(false);
          setMexcData(res.data);
        } else {
          setLoading(false);
        }
      })
      .catch((err: unknown) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getUniSwapData = async () => {
    await axiosInstance
      .get('https://dev.api.covey.academy/api/token/uniswap/')
      .then((res) => {
        const isStatusOk = res.status === 200;
        if (isStatusOk) {
          setLoading(false);
          setUniSwapInfo(res.data);
        } else {
          setLoading(false);
        }
      })
      .catch((err: unknown) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    getMexcData();
    getUniSwapData();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      ) : (
        <>
          <Stats
            heading={`MEXC: ${mexcInfo && mexcInfo.symbol}`}
            img={mexShido}
            icon={questionMarkIcon}
            percentage={
              mexcInfo && mexcInfo?.price_change_percentage !== '' && !isNaN(mexcInfo?.price_change_percentage)
                ? Number(mexcInfo.price_change_percentage).toFixed(2)
                : 'N/A'
            }
            amount={mexcInfo && mexcInfo?.price + ' $'}
          />
          <Stats
            heading={`Uniswap: ${uniSwapInfo && uniSwapInfo.symbol}`}
            img={uniSwapShido}
            icon={questionMarkIcon}
            percentage={
              uniSwapInfo && uniSwapInfo?.price_change_percentage != null && !isNaN(uniSwapInfo.price_change_percentage)
                ? Number(uniSwapInfo.price_change_percentage).toFixed(2)
                : 'N/A'
            }
            amount={uniSwapInfo && uniSwapInfo?.price ? Number(uniSwapInfo.price).toFixed(8) + ' eth' : undefined}
          />
        </>
      )}
    </>
  );
}
export default CurrentPrice;
