import { Text } from '@chakra-ui/react';
import { Row } from 'antd';
import React from 'react';

interface iProp {
  title: string;
  box: string;
  isActive: boolean;
  amount: string;
  address: string;
  selected: boolean;
  onClick: () => void;
}

const Block: React.FC<iProp> = ({ title, box, amount, isActive, address, onClick, selected }) => {
  return (
    <Row
      onClick={onClick}
      style={{
        cursor: 'pointer',
        display: 'flex',
        padding: '24px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
        border: selected ? '2px solid var(--blue-6, #1677FF)' : 'none',
        borderRadius: '20px',
        background: selected ? ' var(--blue-1, #E6F4FF)' : 'var(--Cascader-colorWhite, #FFF)',
      }}
    >
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          flexDirection: 'row',
        }}
      >
        <Text
          color={selected ? 'var(--blue-6, #1677FF)' : 'rgba(0, 0, 0, 0.88)'}
          fontFamily={'Poppins'}
          fontSize={'24px'}
          fontStyle={'normal'}
          fontWeight={'500'}
          lineHeight={'24px'}
        >
          {title}
        </Text>
        <Text
          display={isActive ? 'flex' : 'none'}
          padding="1px var(--Table-paddingXS, 8px)"
          alignItems="center"
          gap="4px"
          borderRadius="var(--Tag-borderRadiusSM, 4px)"
          border="1px solid var(--blue-3, #91CAFF)"
          bg="var(--blue-1, #E6F4FF)"
          color="var(--blue-6, #1677FF)"
          fontFamily="Poppins"
          fontSize="12px"
          fontStyle="normal"
          fontWeight={400}
          lineHeight="20px"
        >
          {box}
        </Text>
      </Row>
      <Row
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '4px',
          alignSelf: 'stretch',
        }}
      >
        <Text
          color="rgba(0, 0, 0, 0.88)"
          fontFamily="Poppins"
          fontSize="36px"
          fontStyle="normal"
          fontWeight={600}
          lineHeight="normal"
        >
          {amount}
        </Text>
        <Text
          color="rgba(0, 0, 0, 0.50)"
          fontFamily="Poppins"
          fontSize="10px"
          fontStyle="normal"
          fontWeight={400}
          lineHeight="normal"
        >
          {address}
        </Text>
      </Row>
    </Row>
  );
};

export default Block;
