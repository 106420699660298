import React, { useState } from 'react';
import { Box, VStack, Text, Button, Input, Flex, Heading, useToast } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import SettingsTable from '../components/SettingsTable';
import TokenAddressModal from '../components/modals/TokenAddressModal';
import HashTag from '../assets/images/pngs/Hashtag.png';
import Inflow from '../assets/images/pngs/Inflow.png';
import { ArrowRightOutlined } from '@ant-design/icons';
import axios from 'axios';

const Settings = () => {
  const [currentView, setCurrentView] = useState('main');
  const [pairAddress, setPairAddress] = useState('');
  const [isTokenAddressModalOpen, setIsTokenAddressModalOpen] = useState(false);
  const toast = useToast();

  const handleViewChange = (view: React.SetStateAction<string>) => {
    setCurrentView(view);
  };

  const handleAddPairAddress = async () => {
    try {
      const response = await axios.patch(
        'https://dev.api.covey.academy/api/user/account/',
        { pair_address: pairAddress },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Pair address updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setPairAddress('');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update pair address',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTokenAddressSuccess = () => {
    // toast({
    //   title: 'Success',
    //   description: 'Token address updated successfully',
    //   status: 'success',
    //   duration: 5000,
    //   isClosable: true,
    // });
    setCurrentView('main');
  };

  const BackButton = () => (
    <Flex
      alignItems="center"
      mb={4}
      cursor="pointer"
      onClick={() => handleViewChange('main')}
      bg="white"
      p={2}
      borderRadius="full"
      boxShadow="md"
      width="fit-content"
    >
      <ChevronLeftIcon color={'black'} />
      <Text ml={2} fontWeight="medium" color={'black'}>
        Back to Settings
      </Text>
    </Flex>
  );

  const boxStyles = {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column' as const,
    alignItems: 'flex-start',
    gap: '24px',
    borderRadius: '20px',
    border: '1px solid rgba(0, 0, 0, 0.06)',
    background: '#FFF',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)',
  };

  return (
    <Box p={6}>
      {currentView === 'main' ? (
        <VStack spacing={6} align="stretch">
          <Box {...boxStyles} width="520px" maxWidth="520px">
            <Heading
              as="h1"
              fontSize="24px"
              fontWeight="500"
              fontStyle="normal"
              color="#000000E0"
              fontFamily="Poppins"
              lineHeight="24px"
            >
              Settings
            </Heading>
            <Text fontSize="md" color="#909090">
              You can always change the items you have set in here.
            </Text>

            <VStack spacing={2} align="stretch" width="100%">
              <Button
                onClick={() => setIsTokenAddressModalOpen(true)}
                variant="unstyled"
                width="100%"
                height="auto"
                p={0}
              >
                <Flex alignItems="center" width="100%">
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    width="40px"
                    height="40px"
                    borderRadius="50%"
                    mr={3}
                  >
                    <img src={HashTag} alt="HashTag" width="20px" height="20px" />
                  </Flex>
                  <Text
                    flex={1}
                    textAlign="left"
                    fontFamily="Poppins"
                    fontSize="14px"
                    fontWeight={400}
                    color="#000000E0"
                  >
                    Add your token address
                  </Text>
                  <ArrowRightOutlined style={{ color: '#d6d6d6' }} />
                </Flex>
              </Button>
              <Button onClick={() => handleViewChange('inOutFlow')} variant="unstyled" width="100%" height="auto" p={0}>
                <Flex alignItems="center" width="100%">
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    width="40px"
                    height="40px"
                    borderRadius="50%"
                    mr={3}
                  >
                    <img src={Inflow} alt="Inflow" width="20px" height="20px" />
                  </Flex>
                  <Text
                    flex={1}
                    textAlign="left"
                    fontFamily="Poppins"
                    fontSize="14px"
                    fontWeight={400}
                    color="#000000E0"
                  >
                    Manage your Inflow and Outflow Addresses
                  </Text>
                  <ArrowRightOutlined style={{ color: '#d6d6d6' }} />
                </Flex>
              </Button>
            </VStack>
          </Box>

          <Box {...boxStyles} width="520px" maxWidth="520px">
            <Heading as="h2" size="md">
              Add your pair address
            </Heading>
            <Text
              color={'#000000E0'}
              fontFamily="Poppins"
              fontSize="24px"
              lineHeight={'24px'}
              fontWeight={500}
              fontStyle="normal"
            >
              Add your pair address
            </Text>
            <Text
              color={'#000000E0'}
              fontFamily="Poppins"
              fontSize="14px"
              lineHeight={'22px'}
              fontWeight={400}
              fontStyle="normal"
            >
              Pair Address
            </Text>
            <Input
              placeholder="Enter Pair Address"
              color={'#000000E0'}
              value={pairAddress}
              onChange={(e) => setPairAddress(e.target.value)}
              width="100%"
            />
            <Button background={'#2d8dfb'} colorScheme="blue" onClick={handleAddPairAddress} width="100%">
              Add
            </Button>
          </Box>
        </VStack>
      ) : (
        <Box>
          <BackButton />
          {currentView === 'inOutFlow' && <SettingsTable />}
        </Box>
      )}
      <TokenAddressModal
        isShowModal={isTokenAddressModalOpen}
        setIsShowModal={setIsTokenAddressModalOpen}
        onSuccess={handleTokenAddressSuccess}
      />
    </Box>
  );
};

export default Settings;
