import { Box, Text, Link } from '@chakra-ui/react';
import React from 'react';
import { loadingStore } from '../../../../store';
import FileUploader from './FileUploader';

function TokenHolderCsvForm() {
  const setStepNumber = loadingStore((state: any) => state.setStepNumber);

  const handleSkip = () => {
    setStepNumber(9); // Assuming 9 is the step for the dashboard
    window.location.href = '/';
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap="10px"
        justifyContent="center"
        alignItems="center"
        height="inherit"
      >
        <Text
          color="var(--Text-Primary-default, #000)"
          textAlign="center"
          fontSize="30px"
          fontStyle="normal"
          fontWeight="600"
          lineHeight="38px"
          fontFamily="Poppins"
        >
          Upload your investors
        </Text>
        <Box
          borderRadius="20px"
          border="1px solid var(--colorSplit, rgba(0, 0, 0, 0.06))"
          background="var(--Cascader-colorWhite, #FFF)"
          boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)"
          display="flex"
          width="401px"
          height="402px"
          padding="24px"
          flexDirection="column"
        >
          <Text
            color="rgba(0, 0, 0, 0.88)"
            textAlign="center"
            fontFamily="Poppins"
            fontSize="24px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="24px"
            marginTop="24px"
            marginBottom="10px"
          >
            Upload file
          </Text>
          <Text
            color="#909090"
            textAlign="center"
            fontFamily="Poppins"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="600"
            lineHeight="22px"
            marginBottom="24px"
          >
            Import a CSV file with the known token holders
          </Text>
          <Box>
            <FileUploader />
          </Box>
          <Link
            color="var(--Text-Primary-description, #909090)"
            textAlign="center"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="400"
            onClick={handleSkip}
            cursor="pointer"
            _hover={{ textDecoration: 'underline' }}
            marginTop="24px"
          >
            Skip
          </Link>
        </Box>
      </Box>
    </>
  );
}

export default TokenHolderCsvForm;
