import React from 'react';
import { Box, Button, FormControl, FormLabel, Input, Text } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { loadingStore } from '../../../../store';

interface ProjectNameData {
  project_name: string;
}

function ProjectNameForm() {
  const toast = useToast();
  const setStepNumber = loadingStore((state: any) => state.setStepNumber);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProjectNameData>();

  const handleFormSubmit = async (data: ProjectNameData) => {
    try {
      await axios.patch('https://dev.api.covey.academy/api/user/account/', data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setStepNumber(2);
      toast({
        title: 'Success',
        description: 'Project name successfully added',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Something went wrong while adding project name',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px" justifyContent="center" alignItems="center" height="inherit">
      <Text
        color="var(--Text-Primary-default, #000)"
        textAlign="center"
        fontSize="30px"
        fontStyle="normal"
        fontWeight="700"
        lineHeight="38px"
        marginBottom="24px"
      >
        Before we start, fill in your details!
      </Text>
      <Box
        borderRadius="20px"
        border="1px solid rgba(0, 0, 0, 0.06)"
        background="#FFF"
        boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)"
        display="flex"
        width="401px"
        padding="24px"
        flexDirection="column"
      >
        <Text
          color="rgba(0, 0, 0, 0.88)"
          textAlign="center"
          fontFamily="Poppins"
          fontSize="24px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="24px"
          marginTop="24px"
          marginBottom="24px"
        >
          What is your project name?
        </Text>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FormControl>
            <Input
              id="project_name"
              {...register('project_name', {
                required: 'Project name is required',
              })}
              placeholder="Enter your project name"
              backgroundColor="#FFF"
              color="#909090"
              border="1px solid rgba(0, 0, 0, 0.15)"
              borderRadius="8px"
            />
          </FormControl>
          <Button
            background="#4096FF"
            color="white"
            _hover={{
              bg: '#494949',
              color: 'white',
            }}
            _active={{
              bg: '#4096FF',
              color: 'white',
            }}
            textTransform="capitalize"
            fontSize="14px"
            fontWeight={500}
            fontStyle="normal"
            width="100%"
            transition="0.2s all"
            mt="24px"
            h="46px"
            type="submit"
          >
            Next
          </Button>
        </form>
      </Box>
    </Box>
  );
}

export default ProjectNameForm;
