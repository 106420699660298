import React from 'react';
import { loadingStore } from '../../../../store';
import TokenAddressForm from './TokenAddressForm';
import LoadingScreen from './Loading';
import InOutFlow from './InOutFlow';
import InOutFlowLoadingScreen from './InOutFlowLoading';
import TokenHolderCsvForm from './TokenHolderCsvForm';
import Congrats from './InvestorsPreviewForm.tsx';
import ProjectNameForm from './ProjectNameForm';
import ProjectFundingForm from './ProjectFundingForm';
import InvestorsPreviewForm from './InvestorsPreviewForm.tsx';

function LoginStepsMain() {
  const stepNumber = loadingStore((state: any) => state.stepNumber);

  return (
    <>
      {stepNumber === 1 && <ProjectNameForm />}
      {stepNumber === 2 && <ProjectFundingForm />}
      {stepNumber === 3 && <TokenAddressForm />}
      {stepNumber === 4 && <LoadingScreen />}
      {stepNumber === 5 && <InOutFlow />}
      {stepNumber === 6 && <InOutFlowLoadingScreen />}
      {stepNumber === 7 && <TokenHolderCsvForm />}
      {stepNumber === 8 && <InvestorsPreviewForm />}
    </>
  );
}
export default LoginStepsMain;
