// Theming
import { ChakraProvider } from '@chakra-ui/react';
import { CustomTheme } from './theme/theme';
// Library
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Views;
import Content from './components/common/Content';
import Overlay from './components/common/Overlay';
import AllTransactions from './pages/AllTransactions';
import Demo from './pages/Demo';
import Login from './pages/Login';
import Sankey from './pages/Sankey';
import Settings from './pages/Settings';
import { SingleTokenHolders } from './pages/SingleTokenHolder';
import { TokenHolders } from './pages/TokenHolders';
import TxInfo from './pages/TxInfo';
import { useLoginStore } from './store';
import SignupMain from './pages/Signup/SignupMain';
import AddressBooks from './pages/AddressBooks';

function AuthenticatedRoutes() {
  return (
    <ChakraProvider theme={CustomTheme}>
      <BrowserRouter>
        <Overlay>
          <Routes>
            <Route path="/" element={<Content />} />
            <Route path="/tokenHolders" element={<TokenHolders />} />
            <Route path="/addressBooks" element={<AddressBooks />} />
            <Route path="/tokenHolders/:id" element={<SingleTokenHolders />} />
            <Route path="/transactions" element={<AllTransactions />} />
            <Route path="/reports" element={<TxInfo />} />
            <Route path="/sankey" element={<Sankey />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </Overlay>
      </BrowserRouter>
    </ChakraProvider>
  );
}

function UnAuthenticatedRoutes() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/sign-up" element={<SignupMain />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

const App = () => {
  const userData = useLoginStore((state: any) => state.userData);
  const isLoggedIn = Object.keys(userData).length > 0 ? true : false;
  if (isLoggedIn) {
    return <AuthenticatedRoutes />;
  }
  return <UnAuthenticatedRoutes />;
};

export default App;
