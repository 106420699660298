import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Account = (props: IconProps) => (
  <Icon width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 0C5.37665 0 3.25 2.12665 3.25 4.75C3.25 7.37335 5.37665 9.5 8 9.5C10.6234 9.5 12.75 7.37335 12.75 4.75C12.75 2.12665 10.6234 0 8 0Z"
      fill="white"
    />
    <path
      d="M5 11C2.37665 11 0.25 13.1266 0.25 15.75C0.25 18.3734 2.37665 20.5 5 20.5H11C13.6234 20.5 15.75 18.3734 15.75 15.75C15.75 13.1266 13.6234 11 11 11H5Z"
      fill="white"
    />
  </Icon>
);
