import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const FileUploadIcon = (props: IconProps) => (
  <Icon width="17px" height="16px" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.8531 4.50938L10.4906 1.14688C10.3969 1.05313 10.2703 1 10.1375 1H3.5C3.22344 1 3 1.22344 3 1.5V14.5C3 14.7766 3.22344 15 3.5 15H13.5C13.7766 15 14 14.7766 14 14.5V4.86406C14 4.73125 13.9469 4.60313 13.8531 4.50938ZM12.8469 5.09375H9.90625V2.15313L12.8469 5.09375ZM12.875 13.875H4.125V2.125H8.84375V5.5C8.84375 5.67405 8.91289 5.84097 9.03596 5.96404C9.15903 6.08711 9.32595 6.15625 9.5 6.15625H12.875V13.875ZM9 7.375C9 7.30625 8.94375 7.25 8.875 7.25H8.125C8.05625 7.25 8 7.30625 8 7.375V9.0625H6.3125C6.24375 9.0625 6.1875 9.11875 6.1875 9.1875V9.9375C6.1875 10.0063 6.24375 10.0625 6.3125 10.0625H8V11.75C8 11.8188 8.05625 11.875 8.125 11.875H8.875C8.94375 11.875 9 11.8188 9 11.75V10.0625H10.6875C10.7562 10.0625 10.8125 10.0063 10.8125 9.9375V9.1875C10.8125 9.11875 10.7562 9.0625 10.6875 9.0625H9V7.375Z"
      fill="#909090"
    />
  </Icon>
);
