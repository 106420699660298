import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { MexcInfo } from './interfaces';

export interface UserDataType {
  user_id: number;
  email: string;
  exp: number;
  access_token: string;
  refresh_token: string;
  token_address: string;
}

export const useLoginStore = create(
  persist(
    (set) => ({
      userData: {},
      walletAddress: '',
      setLoginDetails: (item: UserDataType) => {
        set(() => ({
          userData: {
            ...item,
          },
        }));
      },
      setSelectedWalletAddress: (item: MexcInfo) => {
        set(() => ({
          walletAddress: item,
        }));
      },
    }),

    {
      name: 'coveyInsight', // name of the item in the storage (must be unique)
      //   storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },
  ),
);

export const loadingStore = create((set) => ({
  loading: false,
  mexcInfo: {},
  stepNumber: 1,
  setStepNumber: (stepNumber: number) => {
    set(() => ({
      stepNumber: stepNumber,
    }));
  },
  setLoading: (loadingState: boolean) => {
    set(() => ({
      loading: loadingState,
    }));
  },
  setMexcData: (item: MexcInfo) => {
    set(() => ({
      mexcInfo: {
        ...item,
      },
    }));
  },
}));
