import axiosClient, { AxiosInstance } from 'axios';

const TIMEOUT = 35000; // 35sec
const SERVER_URL = process.env.REACT_APP_API_HOST || 'https://dev.api.covey.academy';
const axios: AxiosInstance = axiosClient.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: TIMEOUT,
  // transformResponse: (res) => transformKeys.toCamelCase(JSON.parse(res)),
});

axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('access_token');

    if (token && config?.headers) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.headers['x-api-key'] = process.env.REACT_APP_TITLE_X_KEY || '9KVvI9QM_98vtE__EYrhCgxFad-6do8fRB9050923uc';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    console.log('err response in intercept resp', err);
    // const originalConfig = err.config;
    // if (err.response && loginBaseUrl.includes(originalConfig.url)) {
    //     if (err.response.status === 401 && !originalConfig._retry) {
    //         originalConfig._retry = true;
    //         store.dispatch(reset)
    //     }
    // }
    return Promise.reject(err);
  },
);

export default axios;
