import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Logout = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path d="M12.6831 11.3625C12.4656 11.145 12.4656 10.785 12.6831 10.5675L14.2056 9.04496L12.6831 7.52246C12.4656 7.30496 12.4656 6.94496 12.6831 6.72746C12.9006 6.50996 13.2606 6.50996 13.4781 6.72746L15.3981 8.64746C15.6156 8.86496 15.6156 9.22496 15.3981 9.44246L13.4781 11.3625C13.3656 11.475 13.2231 11.5275 13.0806 11.5275C12.9381 11.5275 12.7956 11.475 12.6831 11.3625Z" />
    <path d="M7.31982 9.60754C7.01232 9.60754 6.75732 9.35254 6.75732 9.04504C6.75732 8.73754 7.01232 8.48254 7.31982 8.48254H14.9473C15.2548 8.48254 15.5098 8.73754 15.5098 9.04504C15.5098 9.35254 15.2548 9.60754 14.9473 9.60754H7.31982Z" />
    <path d="M2.25732 9C2.25732 5.1375 4.95732 2.4375 8.81982 2.4375C9.12732 2.4375 9.38232 2.6925 9.38232 3C9.38232 3.3075 9.12732 3.5625 8.81982 3.5625C5.61732 3.5625 3.38232 5.7975 3.38232 9C3.38232 12.2025 5.61732 14.4375 8.81982 14.4375C9.12732 14.4375 9.38232 14.6925 9.38232 15C9.38232 15.3075 9.12732 15.5625 8.81982 15.5625C4.95732 15.5625 2.25732 12.8625 2.25732 9Z" />
  </Icon>
);
