import React, { useEffect, useState } from 'react';
import { Box, Text, Image, HStack, Tag, TagLabel, Divider, useToast, Spinner } from '@chakra-ui/react';
import { Table } from 'antd';
import { useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyIcon } from '@chakra-ui/icons';
import MetaMaskImage from '../../../assets/images/pngs/metamask-seeklogo.com 1.png';
import { TagType, WalletInformation } from '../../../interfaces/tokenHolderInterface';
import { TextHeading } from './TextInfoHeading';
import configureAxios from '../../../AxiosClient';
import { loadingStore, useLoginStore } from '../../../store';
import { TokenHolderMain } from './TokenInfoMain';
import { getTagsColor } from '../../../utils/helper';
import { shortenWalletAddress, openInNewTab, getExplorerUrl } from '../../../utils/walletUtils/walletutils';

export const WalletInfo = () => {
  const { state } = useLocation();
  const toast = useToast();
  const axiosInstance = configureAxios();
  const setLoading = loadingStore((state: any) => state.setLoading);
  const loading = loadingStore((state: any) => state.loading);
  const [singleTokenHolder, setSingleTokenHolder] = useState<WalletInformation[]>([]);
  const [tags, setTags] = useState<TagType[]>([]);
  const walletAddress = useLoginStore((state: any) => state.walletAddress);
  const [transactions, setTransactions] = useState([]);

  const getTokenHoldersForWallet = () => {
    axiosInstance
      .get('https://dev.api.covey.academy/api/token-holders/wallet-tokens/', {
        params: {
          wallet_address: walletAddress,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          const adjustedTokens = res.data.map((token: WalletInformation) => ({
            ...token,
            token_balance: (Number(token.token_balance) / Math.pow(10, 18)).toFixed(3),
          }));
          setSingleTokenHolder(adjustedTokens);
        } else {
          setLoading(false);
          toast({
            title: 'There was an error fetching data',
            status: 'error',
            isClosable: true,
          });
        }
      })
      .catch((err: unknown) => {
        setLoading(false);
        console.log(err);
        toast({
          title: 'Error fetching token holder data',
          description: 'Please try again later',
          status: 'error',
          isClosable: true,
        });
      });
  };

  const getTransactions = () => {
    axiosInstance
      .get(`https://dev.api.covey.academy/api/user/tokenholderTransactions/?address=${walletAddress}`)
      .then((res) => {
        if (res.status === 200 && res.data && res.data.results) {
          setTransactions(res.data.results);
        } else {
          toast({
            title: 'There was an error fetching transactions',
            status: 'error',
            isClosable: true,
          });
        }
      })
      .catch((err: unknown) => {
        console.log(err);
        toast({
          title: 'Error fetching transaction data',
          description: 'Please try again later',
          status: 'error',
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    getTokenHoldersForWallet();
    getTransactions();
  }, [walletAddress]);

  useEffect(() => {
    setTags(getTagsColor(state?.labels) || []);
  }, [state?.labels]);

  const onAddressCopy = (text: string) => {
    if (text !== '') {
      toast({
        title: 'Address Copied to clipboard',
        status: 'success',
        isClosable: true,
      });
    }
  };

  const columns = [
    {
      title: 'From',
      dataIndex: 'sender',
      key: 'sender',
      render: (text: string) => shortenWalletAddress(text),
    },
    {
      title: 'To',
      dataIndex: 'receiver',
      key: 'receiver',
      render: (text: string) => shortenWalletAddress(text),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text: string) => Math.round(parseFloat(text)).toString(),
    },
    {
      title: 'Currency',
      dataIndex: 'currency_symbol',
      key: 'currency_symbol',
    },
    {
      title: 'Tx Hash',
      dataIndex: 'transaction_hash',
      key: 'transaction_hash',
      render: (text: string, record: any) => (
        <Text style={{ cursor: 'pointer', color: '#722ED1' }} onClick={() => openInNewTab(getExplorerUrl(text))}>
          {shortenWalletAddress(text)}
        </Text>
      ),
    },
    {
      title: 'Block Time',
      dataIndex: 'block_time',
      key: 'block_time',
    },
  ];

  return (
    <Box>
      <Box display="flex" flexDirection={['column', 'column', 'row']} gap={['40px', '40px', '10px', '40px']}>
        <Box width={['auto', 'auto', '60%', '608px']}>
          <TextHeading text="Wallet" />
          <Box
            width={['inherit', 'inherit', 'auto', 'inherit']}
            borderRadius={'20px'}
            backgroundColor="#FFF"
            padding={'24px'}
            mt="10px"
            minHeight={'615px'}
            height={'100%'}
          >
            <Box height={'65px'} display="flex" alignItems="center" justifyContent={'space-between'}>
              <Box display={'flex'} gap="10px">
                <Image src={MetaMaskImage} />
                <Text fontSize={'18px'} fontWeight="600" color="#000">
                  {shortenWalletAddress(state?.address || walletAddress)}
                </Text>
                <CopyToClipboard text={state?.address || walletAddress} onCopy={onAddressCopy}>
                  <CopyIcon style={{ cursor: 'pointer' }} />
                </CopyToClipboard>
              </Box>
              <Box>
                <HStack spacing={4}>
                  {tags.map((tag, index) => (
                    <Tag
                      key={tag.key || `tag-${index}`}
                      borderRadius={'8px'}
                      border={`1px solid ${tag?.borderColor}`}
                      size={tag?.size}
                      variant="solid"
                      backgroundColor={tag?.bgColor}
                    >
                      <TagLabel color={tag?.textColor} fontSize={'14px'}>
                        {tag?.text}
                      </TagLabel>
                    </Tag>
                  ))}
                </HStack>
              </Box>
            </Box>
            <Divider />

            <Box marginTop={'16px'}>
              <Text fontWeight={600} fontSize={'18px'} color=" #000">
                Token
              </Text>
            </Box>
            {loading ? (
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
            ) : (
              <TokenHolderMain singleTokenHolder={singleTokenHolder} />
            )}
          </Box>
        </Box>
        <Box width={['auto', 'auto', '40%', 'auto']}>
          <TextHeading text="Transactions" />
          <Box
            width={['inherit', 'inherit', 'auto', 'inherit']}
            borderRadius={'20px'}
            backgroundColor="#FFF"
            padding={'24px'}
            mt="10px"
            minHeight={'615px'}
            height={'100%'}
            overflowX="auto"
          >
            <Table
              dataSource={transactions}
              columns={columns}
              rowKey={(record) => record.id || Math.random().toString()}
              pagination={{ pageSize: 10 }}
              scroll={{ x: true }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
