import { Sankey } from '@antv/g2plot';
import { DatePicker, Row, Space, message } from 'antd';
import axios from 'axios';
import { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import Block from '../components/common/Block';
import TimeTab from '../components/common/TimeTab';
import api, { BlockDataParams, SankeyParamsSingle } from '../api/ApiCalls/user';

const { RangePicker } = DatePicker;

const TimeTabData = [
  { key: 'year', title: 'Year' },
  { key: 'month', title: 'Month' },
  { key: 'week', title: 'Week' },
  { key: 'custom', title: 'Custom' },
];

const Demo = () => {
  const [selectedBlock, setSelectedBlock] = useState<string>('Overall');
  const [selectedTime, setSelectedTime] = useState<string>('week');
  const [blockData, setBlockData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  const containerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<Sankey | null>(null);

  useEffect(() => {
    if (selectedTime !== 'custom' || (dateRange[0] && dateRange[1])) {
      fetchBlockData();
    }
  }, [selectedTime, dateRange]);

  useEffect(() => {
    if (selectedBlock && (selectedTime !== 'custom' || (dateRange[0] && dateRange[1]))) {
      fetchSankeyData(selectedBlock);
    }
  }, [selectedBlock, selectedTime, dateRange]);

  const fetchBlockData = async () => {
    setLoading(true);
    const params: BlockDataParams = { time_range: selectedTime };
    if (selectedTime === 'custom' && dateRange[0] && dateRange[1]) {
      params.start_date = dateRange[0].format('YYYY-MM-DD');
      params.end_date = dateRange[1].format('YYYY-MM-DD');
    }

    const { data, error } = await api.getBlockData(params);

    if (error) {
      message.error(error);
    } else {
      const newBlockData = Object.entries(data).map(([title, data]: [string, any]) => ({
        key: title,
        address: data.address,
        box: data.is_receiver === null ? '' : data.is_receiver ? 'Receiver' : 'Sender',
        title: title,
        isActive: title !== 'Overall',
        amount: Number(data.total_amount).toLocaleString(undefined, { maximumFractionDigits: 2 }),
      }));

      const overallBlock = newBlockData.find((block) => block.title === 'Overall');
      const otherBlocks = newBlockData.filter((block) => block.title !== 'Overall');
      setBlockData([overallBlock, ...otherBlocks]);
    }

    setLoading(false);
  };

  const fetchSankeyData = async (title: string) => {
    setLoading(true);
    const params: SankeyParamsSingle = {
      time_range: selectedTime,
      inflow_outflow_title: title,
    };
    if (selectedTime === 'custom' && dateRange[0] && dateRange[1]) {
      params.start_date = dateRange[0].format('YYYY-MM-DD');
      params.end_date = dateRange[1].format('YYYY-MM-DD');
    }

    const { data, error } = await api.getSankeyTransactionsSingle(params);

    if (error) {
      message.error(error);
    } else {
      renderSankey(data);
    }

    setLoading(false);
  };

  const renderSankey = (data: any[]) => {
    if (containerRef.current) {
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      chartRef.current = new Sankey(containerRef.current, {
        data: data,
        sourceField: 'source',
        targetField: 'target',
        weightField: 'value',
        nodeWidthRatio: 0.008,
        nodePaddingRatio: 0.03,
        nodeDraggable: true,
      });

      chartRef.current.render();
    }
  };

  const handleBlockClick = (key: string, title: string) => {
    setSelectedBlock(title);
  };

  const handleTimeTabClick = (key: string) => {
    setSelectedTime(key);
    if (key !== 'custom') {
      setDateRange([null, null]);
    }
  };

  const handleDateRangeChange = (dates: null | [Dayjs | null, Dayjs | null]) => {
    if (dates) {
      setDateRange(dates);
    } else {
      setDateRange([null, null]);
    }
  };

  return (
    <Space direction="vertical" size={16} style={{ width: '100%', gap: '24px' }}>
      <div style={{ overflow: 'auto', width: '100%', gap: '10px' }}>
        <Row
          style={{
            display: 'inline-flex',
            flexWrap: 'nowrap',
            gap: '10px',
            padding: '10px',
          }}
        >
          {blockData.map((item) => (
            <Block
              key={item.key}
              address={item.address}
              box={item.box}
              title={item.title}
              isActive={item.isActive}
              amount={item.amount}
              selected={selectedBlock === item.title}
              onClick={() => handleBlockClick(item.key, item.title)}
            />
          ))}
        </Row>
      </div>

      <Row
        style={{
          display: 'flex',
          padding: '20px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          borderRadius: '20px',
          background: '#FFF',
        }}
      >
        <Row style={{ width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
          <Space style={{ display: 'flex', justifyContent: 'center' }}>
            {selectedTime === 'custom' && <RangePicker value={dateRange} onChange={handleDateRangeChange} />}
            {TimeTabData.map((item) => (
              <TimeTab
                key={item.key}
                title={item.title}
                selected={selectedTime === item.key}
                onClick={() => handleTimeTabClick(item.key)}
              />
            ))}
          </Space>
        </Row>
        <div ref={containerRef} style={{ width: '100%', height: 600 }} />
      </Row>
    </Space>
  );
};

export default Demo;
