import { AddIcon } from '@chakra-ui/icons';
import { Button as ChakraButton } from '@chakra-ui/react';
import { Button as AntButton, Space, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DeleteModal from './modals/DeleteModal';
import SuccessModal from './modals/SuccessModal';

interface DataType {
  id: number;
  key: string;
  title: string;
  address: string;
  status: 'Receiver' | 'Sender';
}

interface ApiResponse {
  [key: string]: {
    id: number;
    title: string;
    address: string;
    is_receiver: boolean;
  };
}

const SettingTable: React.FC = () => {
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState<DataType | null>(null);
  const [deletingRecord, setDeletingRecord] = useState<DataType | null>(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get<ApiResponse>(
        'https://dev.api.covey.academy/api/user/settings/inflow-outflow-addresses/',
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );
      const formattedData: DataType[] = Object.entries(response.data).map(([key, value]) => ({
        key,
        id: value.id,
        title: value.title,
        address: value.address,
        status: value.is_receiver ? 'Receiver' : 'Sender',
      }));
      setTableData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEdit = (record: DataType) => {
    setEditingRecord(record);
    setIsAddingNew(false);
    setIsShowSuccessModal(true);
  };

  const handleDelete = (record: DataType) => {
    setDeletingRecord(record);
    setIsShowDeleteModal(true);
  };

  const handleAdd = () => {
    setEditingRecord(null);
    setIsAddingNew(true);
    setIsShowSuccessModal(true);
  };

  const handleSaveChanges = async (updatedRecord: DataType) => {
    try {
      const baseUrl = 'https://dev.api.covey.academy/api/user/update-inflow-outflow-address-single/';
      const headers = { Authorization: `Bearer ${localStorage.getItem('access_token')}` };
      const data = {
        title: updatedRecord.title,
        address: updatedRecord.address,
        is_receiver: updatedRecord.status === 'Receiver',
      };

      let response;
      if (isAddingNew) {
        // Use POST for adding a new address
        response = await axios.post(baseUrl, data, { headers });
        console.log('New address added:', response.data);
      } else {
        // Use PATCH for updating an existing address
        const addressId = editingRecord?.id;
        response = await axios.patch(`${baseUrl}/${addressId}/`, data, { headers });
        console.log('Address updated:', response.data);
      }

      // Fetch updated data and reset state
      await fetchData();
      setIsShowSuccessModal(false);
      setEditingRecord(null);
      setIsAddingNew(false);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const handleConfirmDelete = async () => {
    if (deletingRecord) {
      try {
        await axios.delete(
          `https://dev.api.covey.academy/api/user/update-inflow-outflow-address-single/${deletingRecord.id}/`,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
          },
        );
        fetchData();
        setIsShowDeleteModal(false);
        setDeletingRecord(null);
      } catch (error) {
        console.error('Error deleting record:', error);
      }
    }
  };

  const getUniqueFilters = (data: DataType[], key: keyof DataType) => {
    const uniqueValues = data.reduce((acc: string[], curr) => {
      if (!acc.includes(curr[key] as string)) {
        acc.push(curr[key] as string);
      }
      return acc;
    }, []);
    return uniqueValues.map((value) => ({ text: value, value }));
  };

  const columns: ColumnType<DataType>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      filters: getUniqueFilters(tableData, 'title'),
      onFilter: (value, record) => record.title.indexOf(value as string) === 0,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a.address.localeCompare(b.address),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: 'Receiver' | 'Sender') => (
        <span
          style={{
            color: 'var(--blue-6, #1677FF)',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            padding: '1px 8px',
            borderRadius: '4px',
            border: '1px solid var(--blue-3, #91CAFF)',
            background: 'var(--blue-1, #E6F4FF)',
          }}
        >
          {status.toUpperCase()}
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <AntButton
            type="link"
            onClick={() => handleEdit(record)}
            style={{ color: '#2D8DFB', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}
          >
            Edit
          </AntButton>
          <AntButton
            type="link"
            onClick={() => handleDelete(record)}
            style={{ color: '#FF4D4F', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}
          >
            Delete
          </AntButton>
        </Space>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <ChakraButton leftIcon={<AddIcon />} colorScheme="blue" backgroundColor={'#2d8dfb'} onClick={handleAdd} mb={4}>
        Add New Entry
      </ChakraButton>
      <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} />
      <SuccessModal
        isShowModal={isShowSuccessModal}
        setIsShowModal={setIsShowSuccessModal}
        editingRecord={editingRecord}
        onSaveChanges={handleSaveChanges}
        isAddingNew={isAddingNew}
      />
      <DeleteModal
        showModal={isShowDeleteModal}
        isShowModal={setIsShowDeleteModal}
        onConfirmDelete={handleConfirmDelete}
      />
    </>
  );
};

export default SettingTable;
