import { Table, Thead, Tr, Th, Tbody, Td, TableContainer, useToast, Spinner, Link, Center } from '@chakra-ui/react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { PaginationTable } from 'table-pagination-chakra-ui';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import configureAxios from '../AxiosClient';
import { TagRow } from '../components/tokenHolder/tags/TagRow';
import { TableHeaderTextProps, TableDataTextProps, TokenHolder } from '../interfaces/tokenHolderInterface';
import { loadingStore, useLoginStore } from '../store';
import { numberWithCommas } from '../utils/helper';

export const TableHeaderText = (props: TableHeaderTextProps) => {
  const { text } = props;
  return (
    <Th textTransform="capitalize" fontSize={'16px'} fontWeight="600" color="#000">
      {text}
    </Th>
  );
};

export const TableDataText = (props: TableDataTextProps) => {
  const { text } = props;
  return (
    <Td color="#000" fontSize="14px">
      {text}
    </Td>
  );
};

export const TokenHolders = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const axiosInstance = configureAxios();
  const setLoading = loadingStore((state: any) => state.setLoading);
  const loading = loadingStore((state: any) => state.loading);
  const [tokenHolders, setTokenHolder] = useState<TokenHolder[]>([]);
  const [reTrigger, setReTrigger] = useState(false);
  const [pageIndex, setPageIndex] = useState(() => {
    const savedPage = localStorage.getItem('tokenHoldersPageIndex');
    return savedPage ? parseInt(savedPage, 10) : 0;
  });
  const [pageSize, setPageSize] = useState(() => {
    const savedSize = localStorage.getItem('tokenHoldersPageSize');
    return savedSize ? parseInt(savedSize, 10) : 10;
  });
  const [totalCount, setTotalCount] = useState(0);
  const setSelectedWalletAddress = useLoginStore((state: any) => state.setSelectedWalletAddress);

  const userData = useLoginStore((state: any) => state.userData);

  const navigateToSinglePage = (address: string, labels: string[]) => {
    setSelectedWalletAddress(address);
    navigate(`/tokenHolders/${address}`, {
      state: {
        address: address,
        labels: labels,
      },
    });
  };

  const calculateAdjustedBalance = (balance: string, decimals: string) => {
    const adjustedBalance = Number(balance) / Math.pow(10, Number(decimals));
    return numberWithCommas(adjustedBalance.toFixed(2));
  };

  const getTokenHolders = () => {
    setLoading(true);
    axiosInstance
      .get(`https://dev.api.covey.academy/api/token-holders/?page=${pageIndex + 1}&page_size=${pageSize}`)
      .then((res) => {
        const isStatusOk = res.status === 200;
        if (isStatusOk) {
          console.log('Token holders response:', res.data); // Add this line
          setTokenHolder(res.data.results);
          setTotalCount(res.data.count);
        } else {
          toast({
            title: 'There was an error fetching data',
            status: 'error',
            isClosable: true,
          });
        }
      })
      .catch((err: unknown) => {
        console.error('Error fetching token holders:', err);
        toast({
          title: 'Error fetching token holders',
          description: 'Please try again later',
          status: 'error',
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTokenHolders();
  }, [pageIndex, pageSize, reTrigger]);

  useEffect(() => {
    localStorage.setItem('tokenHoldersPageIndex', pageIndex.toString());
    localStorage.setItem('tokenHoldersPageSize', pageSize.toString());
  }, [pageIndex, pageSize]);

  const handleSetPageIndex = (index: number) => {
    setPageIndex(index);
    localStorage.setItem('tokenHoldersPageIndex', index.toString());
  };

  const handleSetPageSize = (size: number) => {
    setPageSize(size);
    localStorage.setItem('tokenHoldersPageSize', size.toString());
    setPageIndex(0);
    localStorage.setItem('tokenHoldersPageIndex', '0');
  };
  const updateTag = async (id: number, labels: string[]) => {
    setLoading(true);
    try {
      const response = await axiosInstance.put(`https://dev.api.covey.academy/api/token-holders/${id}/update/`, {
        labels,
      });
      if (response.status === 200) {
        // Update the local state with the new data
        setTokenHolder((prevHolders) =>
          prevHolders.map((holder) => (holder.id === id ? { ...holder, labels: response.data.labels } : holder)),
        );
        setReTrigger(!reTrigger);
        toast({
          title: 'Tags updated successfully',
          status: 'success',
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating tags:', error);
      toast({
        title: 'Error updating tags',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteTag = async (id: number, labelToDelete: string) => {
    setLoading(true);
    try {
      const tokenHolder = tokenHolders.find((th) => th.id === id);
      if (!tokenHolder) throw new Error('TokenHolder not found');

      const updatedLabels = tokenHolder.labels.filter((label) => label !== labelToDelete);
      await axiosInstance.put(`https://dev.api.covey.academy/api/token-holders/${id}/update/`, {
        labels: updatedLabels,
      });
      setReTrigger(!reTrigger);
      toast({
        title: 'Tag deleted successfully',
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting tag:', error);
      toast({
        title: 'Error deleting tag',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </Center>
    );
  }

  return (
    <>
      <TableContainer overflowY="scroll">
        <Table marginTop={'40px'} backgroundColor="#FFF" borderRadius={'20px'} size="lg">
          <Thead marginBottom={'16px'}>
            <Tr>
              <TableHeaderText text={'Wallet Address'} />
              <TableHeaderText text={'Balance'} />
              <TableHeaderText text={'Tag'} />
            </Tr>
          </Thead>
          <Tbody>
            {tokenHolders &&
              tokenHolders.map((tokenHolder: TokenHolder) => (
                <Tr key={tokenHolder?.id}>
                  <Td>
                    <Link color="#4096ff" onClick={() => navigateToSinglePage(tokenHolder.address, tokenHolder.labels)}>
                      {`${tokenHolder?.address.slice(0, 6)}...${tokenHolder?.address?.slice(-8)}`}
                    </Link>
                  </Td>
                  <TableDataText
                    text={calculateAdjustedBalance(tokenHolder?.balance, tokenHolder?.contract_decimals)}
                  />
                  <Td>
                    <TagRow
                      label={tokenHolder?.labels || []}
                      id={tokenHolder?.id}
                      setReTrigger={setReTrigger}
                      reTrigger={reTrigger}
                      updateTag={updateTag}
                      deleteTag={deleteTag}
                    />
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      {tokenHolders && tokenHolders.length > 0 && (
        <PaginationTable
          colorScheme="blue"
          pageSize={pageSize}
          labelOptions="Items"
          setPageSize={handleSetPageSize}
          pageIndex={pageIndex}
          setPageIndex={handleSetPageIndex}
          totalItemsCount={totalCount}
          pageSizeOptions={[10, 25, 50]}
        />
      )}
    </>
  );
};
