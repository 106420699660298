import React, { useState, useEffect } from 'react';
import { DatePicker, Space, Button, Select, Table, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import api, { TransactionParams } from '../api/ApiCalls/user';

const { RangePicker } = DatePicker;
const { Option } = Select;

function AllTransactions() {
  const [dateRange, setDateRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
  const [responseData, setResponseData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [inflowOutflowTitle, setInflowOutflowTitle] = useState<string | undefined>(undefined);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [inflowOutflowTitles, setInflowOutflowTitles] = useState<string[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const handleDateChange = (dates: any) => {
    setDateRange(dates);
  };

  const handleTitleChange = (value: string) => {
    setInflowOutflowTitle(value === 'All' ? undefined : value);
  };

  const handleSortChange = (value: 'asc' | 'desc') => {
    setSortOrder(value);
  };

  const fetchData = async (page = 1, pageSize = 10) => {
    setLoading(true);
    const params: TransactionParams = {
      page,
      page_size: pageSize,
      sort: sortOrder,
    };

    if (dateRange[0] && dateRange[1]) {
      params.start_date = dateRange[0].format('YYYY-MM-DD');
      params.end_date = dateRange[1].format('YYYY-MM-DD');
    }

    if (inflowOutflowTitle) {
      params.inflow_outflow_title = inflowOutflowTitle;
    }

    const { data, error } = await api.getUserTransactions(params);

    if (error) {
      setError(error);
      setResponseData([]);
      message.error(error);
    } else {
      setResponseData(data.results);
      setPagination({
        ...pagination,
        current: page,
        total: data.count,
      });
      setInflowOutflowTitles(['All', ...data.inflow_outflow_titles]);
      setError(null);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTableChange = (pagination: any) => {
    fetchData(pagination.current, pagination.pageSize);
  };

  const columns = [
    {
      title: 'Transaction Hash',
      dataIndex: 'transaction_hash',
      key: 'transaction_hash',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (value: any) => {
        const numValue = parseFloat(value);
        if (isNaN(numValue)) return value;
        return numValue.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
      sorter: true,
    },
    {
      title: 'Receiver',
      dataIndex: 'receiver',
      key: 'receiver',
    },
    {
      title: 'Sender',
      dataIndex: 'sender',
      key: 'sender',
    },
    {
      title: 'Transfer Type',
      dataIndex: 'transfer_type',
      key: 'transfer_type',
    },
    {
      title: 'Block Time',
      dataIndex: 'block_time',
      key: 'block_time',
      render: (value: string) => (moment(value).isValid() ? moment(value).format('YYYY-MM-DD HH:mm:ss') : value),
    },
  ];

  return (
    <Space direction="vertical" size={12} style={{ width: '100%' }}>
      <Space>
        <RangePicker onChange={handleDateChange} />
        <Select
          style={{ width: 200 }}
          placeholder="Select Inflow/Outflow Title"
          onChange={handleTitleChange}
          allowClear
          defaultValue="All"
        >
          {inflowOutflowTitles.map((title) => (
            <Option key={title} value={title}>
              {title}
            </Option>
          ))}
        </Select>
        <Select style={{ width: 150 }} placeholder="Sort Order" onChange={handleSortChange} value={sortOrder}>
          <Option value="asc">Ascending</Option>
          <Option value="desc">Descending</Option>
        </Select>
        <Button onClick={() => fetchData(1, pagination.pageSize)}>Fetch Data</Button>
      </Space>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <Table
        columns={columns}
        dataSource={responseData}
        rowKey="transaction_hash"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </Space>
  );
}

export default AllTransactions;
