import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import { SidebarItemProps } from '../../interfaces';
import { useLoginStore } from '../../store';

function SidebarItem({
  sideBarItemKey,
  sideBarIcon,
  text,
  sideBarOpen,
  active,
  last,
  setActiveView,
}: SidebarItemProps) {
  const setLoginDetails = useLoginStore((state: any) => state.setLoginDetails);
  const setFromLoadingPage = useLoginStore((state: any) => state.setFromLoadingPage);

  const onLogoutClick = () => {
    if (sideBarItemKey === 'logout') {
      // navigate('/');
      setLoginDetails({});
      setFromLoadingPage(false);
    }
  };
  return (
    <Box width={['100%']} height={['50px']} display="flex" alignItems="center" onClick={onLogoutClick}>
      {sideBarOpen ? (
        <Button
          leftIcon={sideBarIcon}
          variant="ghost"
          background={last ? '#494949' : active ? '#4096FF' : 'unset'}
          color={active ? 'white' : '#909090'}
          _hover={{
            bg: active ? '#4096FF' : '#494949',
            color: 'white',
          }}
          _active={{
            bg: '#4096FF',
            color: 'white',
          }}
          textTransform="capitalize"
          fontSize={['14px']}
          onClick={() => !last && setActiveView(sideBarItemKey)}
          fontWeight={500}
          fontStyle="normal"
          width="inherit"
          justifyContent="flex-start"
          transition="0.2s all"
        >
          {text}
        </Button>
      ) : (
        <Box
          width={'48px'}
          height="40px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          cursor="pointer"
          onClick={() => !last && setActiveView(sideBarItemKey)}
          background={last ? '#494949' : active ? '#4096FF' : 'unset'}
          borderRadius="10px"
          color={active ? 'white' : '#909090'}
          _hover={{
            bg: active ? '#4096FF' : '#494949',
            color: 'white',
          }}
          _active={{
            bg: '#4096FF',
            color: 'white',
          }}
          transition="0.2s all"
        >
          {sideBarIcon}
        </Box>
      )}
    </Box>
  );
}

export default SidebarItem;
