import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const HomeIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6575 17.0625C2.6025 17.0625 0.9375 15.39 0.9375 13.335V7.77748C0.9375 6.75748 1.5675 5.47498 2.3775 4.84498L6.42 1.69498C7.635 0.749981 9.5775 0.70498 10.8375 1.58998L15.4725 4.83748C16.365 5.45998 17.0625 6.79498 17.0625 7.88248V13.3425C17.0625 15.39 15.3975 17.0625 13.3425 17.0625H4.6575ZM3.0675 5.72998C2.535 6.14998 2.0625 7.10248 2.0625 7.77748V13.335C2.0625 14.7675 3.225 15.9375 4.6575 15.9375H13.3425C14.775 15.9375 15.9375 14.775 15.9375 13.3425V7.88248C15.9375 7.16248 15.42 6.16498 14.8275 5.75998L10.1925 2.51248C9.3375 1.91248 7.9275 1.94248 7.11 2.57998L3.0675 5.72998Z"
      fill="white"
    />
    <path
      d="M8.4375 13.5V11.25C8.4375 10.9425 8.6925 10.6875 9 10.6875C9.3075 10.6875 9.5625 10.9425 9.5625 11.25V13.5C9.5625 13.8075 9.3075 14.0625 9 14.0625C8.6925 14.0625 8.4375 13.8075 8.4375 13.5Z"
      fill="white"
    />
  </Icon>
);
