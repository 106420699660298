import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const ArrowIcon = (props: IconProps) => (
  <Icon width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10.3848C20 4.86192 15.5228 0.384766 10 0.384766C4.47715 0.384766 6.78525e-07 4.86192 4.37114e-07 10.3848C1.95703e-07 15.9076 4.47715 20.3848 10 20.3848C15.5228 20.3848 20 15.9076 20 10.3848ZM8.52796 4.85207C8.23376 4.56049 7.75889 4.56261 7.46731 4.85681C7.17573 5.151 7.17784 5.62587 7.47204 5.91746L9.23501 7.66476C9.9505 8.3739 10.4413 8.86194 10.7734 9.27547C11.096 9.67717 11.2067 9.93518 11.2361 10.1663C11.2546 10.3114 11.2546 10.4582 11.2361 10.6033C11.2067 10.8343 11.096 11.0924 10.7734 11.4941C10.4413 11.9076 9.95051 12.3956 9.23501 13.1048L7.47204 14.8521C7.17784 15.1437 7.17573 15.6185 7.46731 15.9127C7.75889 16.2069 8.23376 16.209 8.52796 15.9175L10.3227 14.1387C10.9987 13.4687 11.5511 12.9212 11.9429 12.4333C12.3504 11.926 12.6453 11.411 12.7241 10.7929C12.7586 10.5219 12.7586 10.2476 12.7241 9.9766C12.6453 9.35849 12.3504 8.84355 11.9429 8.33623C11.5511 7.84835 10.9987 7.30081 10.3227 6.63087L8.52796 4.85207Z"
      fill="#353535"
    />
  </Icon>
);
